import { configureStore } from "@reduxjs/toolkit";
import attributeReducer from "./reducers/attributeReducer";
import authReducer from "./reducers/authReducer";
import bannerReducer from "./reducers/bannerReducer";
import categoryReducer from "./reducers/categoryReducer";
import customerReducer from "./reducers/customerReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import orderReducer from "./reducers/orderReducer";
import productReducer from "./reducers/productReducer";
import ProductReviewsReducer from "./reducers/ProductReviewsReducer";
import subCategoryReducer from "./reducers/subCategoryReducer";
import supplierReducer from "./reducers/supplierReducer";
import supplierReviewsReducer from "./reducers/supplierReviewsReducer";
import withdrawReducer from "./reducers/withdrawReducer";
import notificationReducer from "./reducers/notificationReducer";
import catelogReducer from "./reducers/catelogReducer";
import sectionReducer from "./reducers/sectionReducer";

const store = configureStore({
    reducer: {
        auth : authReducer , 
        attributes : attributeReducer , 
        category : categoryReducer ,
        subCategory : subCategoryReducer ,
        product : productReducer ,
        supplier : supplierReducer ,
        banner : bannerReducer ,
        customer : customerReducer , 
        order : orderReducer ,
        productReview : ProductReviewsReducer , 
        supplierReview : supplierReviewsReducer ,
        dashboard : dashboardReducer,
        withdraw:withdrawReducer,
        notification:notificationReducer,
        catelog:catelogReducer,
        section:sectionReducer
    },
});

export default store;