import Statistics from 'components/home/Statistics'
import { useState } from 'react'

const Home = () => {
    const [filter , setFilter] = useState('');
   
    return (
        <div className='sm:px-8 px-2'>
               <div className='flex items-center justify-between py-6'>
            <div className='flex flex-col gap-1 '>
                <h1 className='text-2xl font-semibold'>Welcome, Admin.</h1>
                <p className='sm:w-full w-[90%] sm:text-base text-[15px]'>Welcome Admin, here is your business statistics.</p>
            </div>
            <div>
                <select 
                className='border py-1.5 px-4 outline-none rounded-md border-cyan-400 sm:text-base text-sm'
                onChange={(e) => setFilter(e.target.value)}
                >
                    <option value="">Overall Statistics</option>
                    <option value="today">Today Statistics</option>
                    <option value="month">This Month Statistics</option>
                </select>
            </div>
        </div>
            <div>
                <Statistics filter={filter} />
            </div>
        </div>
    )
}

export default Home