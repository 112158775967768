import React, { useRef, useState } from 'react'
import useClickOutside from 'utils/clickOutside';

const CouponsTable = () => {
    const dropMenuRef = useRef(null);
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    

    useClickOutside(dropMenuRef , () => setShowDropMenu(false));

    return (
        <div className="overflow-x-auto rounded-lg">
            <table className="w-full table-auto overflow-x-auto ">
                <thead className="bg-lightSlate border-b text-[13px]">
                    <tr>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            #
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            TITLE
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            CODE
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            MIN PURCHASE
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            MAX DISCOUNT
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            DISCOUNT
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            DISCOUNT TYPE	
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            START DATE	
                        </th>   
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            EXPIRE DATE	
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            STATUS
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-center">
                            ACTION	
                        </th>
                        
                    </tr>
                </thead>
                <tbody className='text-[13px] text-gray-500'>
                   {
                        [...Array(6).keys()].map((item , i) => (
                            <tr className="bg-white border-b transition duration-300 ease-in-out">
                            <td className="px-6 py-4 whitespace-nowrap  font-medium text-gray-500">{i}</td>
                            <td className=" text-gray-500 font-normal px-6 py-4 whitespace-nowrap">
                                New Bangla year   
                            </td>
                            <td className=" px-6 py-4 whitespace-nowrap">
                                10045   
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                150.00$
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                50.00$
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                50
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                Amount
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                            21-03-2021	
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                28-03-2021	

                            </td>
                            <td className="text-smont-light px-6 py-4 whitespace-nowrap">
                                <p className='border py-2.5 px-3 flex items-center gap-2 cursor-pointer'>
                                    <div 
                                    className='bg-green-500 rounded-full w-[7px] h-[7px] '></div>
                                    <span>Active</span>
                                </p>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap ">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                    {/* DROP MENU */}
                                    {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                            <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer flex items-center gap-1'>
                                                <i className="uil uil-eye text-lg"></i>
                                                <span>View</span>
                                            </div>
                                            {/* <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'>
                                                Delete
                                            </div> */}
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
           
        </div>
    )
}

export default CouponsTable;