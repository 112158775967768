// import { products } from 'data/products'
import { stroageBaseURL } from 'config/api';
import React from 'react'
import { useSelector } from 'react-redux'

const TopSellings = () => {
    const { stats : { topProducts : products } } = useSelector(state => state.dashboard);

    return (
        <div>
            <div className='py-4 px-4 flex items-center justify-between border-b'>
                <div className='flex items-center gap-2'>
                    <i className="uil uil-chart text-xl"></i>
                    <h4 className='font-semibold'>Top selling products</h4>
                </div>
                <div className='text-5xl text-grayText'>
                    <i className="uil uil-gift"></i>
                </div>
            </div>
            {
                products?.length > 0 
                ? 
                    <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 py-8 px-4'>
                        {
                            products?.map((item , i) => (
                                <div 
                                className='border-2 rounded-lg py-2 px-4 flex flex-col gap-2' 
                                key={i}
                                >
                                    <div className='bg-orange-600 w-fit text-pure rounded-md py-1 px-4 text-[10px]'>Sold {item.count}</div>
                                    <div className='w-full h-[100px] py-2'>
                                        <img 
                                        src={stroageBaseURL + "/products/" + item?.product?.images[0] } 
                                        alt={item?.product?.name} 
                                        className='w-full h-full object-cover'
                                        />
                                    </div>
                                    <div className='text-center text-sm text-grayText'>
                                        {item?.product?.name}
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                :  
                    <div className="h-[200px] flex items-center justify-center text-xl font-semibold">
                        No item found.
                    </div>
            }
        </div>
    )
}

export default TopSellings