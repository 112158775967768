import Select from 'react-select';

const SelectBox = ( { options , setValue , value = '' } ) => {
    
    const handleChange = e => {
        setValue(e.value);      
    }

    const selectedOption = options.find(option => option.value === value);

    return (
        <Select
            options={options}
            className='outline-none focus:border-red-500'
            inputId='select-box-input'
            onChange={handleChange}
            value={selectedOption}
        />
    );
}

export default SelectBox;