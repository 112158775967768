import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import Search from 'components/global/Search';
import ProductsTable from 'components/products/ProductsTable';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getProducts } from 'redux/actions/productActions';

const Products = () => {
    const dispatch = useDispatch();
    const { loading , currentPage , products } = useSelector(state => state.product);

    useEffect(() => {
        dispatch(getProducts(toast))
    }, [currentPage])

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center gap-4'>
                <h1 className='main-heading'>
                    <i className="uil uil-list-ul"></i>
                    <span>Product List</span>
                </h1>
            </div>
            <div className='shadow-bg  mt-6'>
                <div className='flex sm:items-center justify-between sm:flex-row flex-col py-6 sm:px-4 px-2 gap-6'>
                    <form>
                        <div>
                            <Search />
                        </div>
                    </form>
                    <Link to='/product/add-new'>
                        <div className='btn-primary p-sm flex gap-2 w-fit'>
                            <i className="uil uil-plus-circle text-lg"></i>
                            <span>Add New Products</span>
                        </div>
                    </Link>
                </div>
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    products?.length > 0 
                    ? 
                        <div>
                            <ProductsTable />
                        </div>
                    : 
                        <ItemNotFound />
                }
            </div>
        </div>
    )
}

export default Products;