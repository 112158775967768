import Axios from "config/api";
import { setDashboardStats, setError, setLoading } from "redux/reducers/dashboardReducer";


export const fetchDashboardStats = (filter) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { stats } } } = await Axios.get(`/admin/dashboard/stats?dateFilter=${filter}` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        }) ;
        dispatch(setDashboardStats(stats));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        dispatch(setError(err?.response?.data?.message || err?.message || 'something went wrong.'))
    }
}