import Heading from 'components/global/Heading'
import React, {useEffect,  useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Img1 from 'assets/images/img1.jpg';
import { createNotification } from 'redux/actions/notificationActions';
import { ClipLoader } from 'react-spinners';
import useClickOutside from 'utils/clickOutside';
import { createCategory, updateCat } from 'redux/actions/categoryActions';
import { stroageBaseURL } from 'config/api';
import isBase64 from 'utils/isBase64';
import { useNavigate } from "react-router-dom";
import toastError from '../../utils/toastError';
import Axios from "../../config/api";
import debounce from 'lodash/debounce';
import Select from 'react-select';
import SelectBox from 'components/global/SelectBox';

const AddCatelog = () => {
    const navigate = useNavigate();

    const [title , setTitle] = useState('');
    const [description , setDescription] = useState('');
    const dispatch = useDispatch();
    const { createLoading , updateLoading } = useSelector(state => state.notification);
    const [searchLoading , setSearchLoading] = useState(false);
    const [users , setUsers] = useState([]);
    const { user } = useSelector(state => state.auth);
    const [to , setTo] = useState(null);
    const [loading , setLoading] = useState(false);
    const [suppliers , setSuppliers] = useState([]);
    const [supplier , setSupplier] = useState('');

    const submitHandler = async (e) => {
        e.preventDefault();
      
        console.log(to)
        
            try {
                setLoading(true);
                // const productIds = [...new Set(to.map(item => item._id.toString()))];
                console.log(to)
                const productIds = to.map(obj => obj.value._id);
                let _data = {
                   name: title , productIds : productIds  , supplier:supplier
                } 
                console.log(_data);
    
                const { data : { data : { message } } } = await Axios.post('/catelogs' , _data , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                setTitle('');
                setTo(null)
                toast.success(message);
                setLoading(false);
                navigate('/catelog');
                
            } catch (error) {
                setLoading(false);
                toastError(error);
            }
          
    }
    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                setLoading(true);
                const { data : { data : { docs } } } = await Axios(`/suppliers/get/all`);
                setSuppliers(docs);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err?.response?.data?.message || err?.message || 'something went wrong.')
            }
        };
        fetchSuppliers();
    } , []);
    const searchUsers = async (query) => {
        try {
            setSearchLoading(true);
            const { data : { data : { docs } } } = await Axios(`/products/search?keyword=${query}&owner=${supplier}`);
            setUsers(docs?.filter(doc => doc?._id !== user?._id)); 
            setSearchLoading(false);
        } catch (error) {
            setSearchLoading(false);
            toastError(error);
        }
    }
    
    const debouncedSearch = debounce(searchUsers , 500);
    const handleInputChange = (value) => {
        debouncedSearch(value);
    };


    return (
        <div className='sm:p-6 px-2 py-4'>
            <div className='flex items-center gap-2'>
                <i className="uil uil-bell"></i>
                <Heading title='Add Catelog' icon={false} />
            </div>
            
            <form 
                    className='flex flex-col gap-4 mt-4'
                    onSubmit={submitHandler}
                    >
                <div className='flex flex-col gap-2'>
                    <label className='text-[15px]'>Title</label>
                    <input 
                    type="text" 
                    placeholder='New Notification'
                    className='textField'
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    />
                </div>
                {/* <div className='flex flex-col gap-2'>
                    <label className='text-[15px]'>Description</label>
                    <textarea 
                    type="text" 
                    placeholder='Description...'
                    className='textField resize-none focus:border-blue-500 '
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    />
                </div> */}
                <div className='flex-1 flex flex-col gap-1.5'>
                            <label>Supplier</label>
                            {
                                loading
                                ?
                                    <ClipLoader size={20} />
                                : 
                                suppliers?.length > 0 
                                ? 
                                    <SelectBox 
                                    options={suppliers?.map(item => (
                                        { 
                                            label : item?.firstName + " " + item?.lastName , 
                                            value : item?._id 
                                        }
                                    ))}
                                    value={supplier}
                                    setValue={setSupplier}
                                    required
                                    />
                                : 
                                    <div>``
                                        No Supplier found.
                                    </div>
                            }
                        </div>
                <div>
                            <h2 className="text-primary font-semibold flex items-center gap-2">
                                <span>Products List</span>
                                {
                                    searchLoading
                                    ? 
                                        <ClipLoader size={15} color='white' />
                                    : ''
                                }
                            </h2>
                            <Select
                            onInputChange={handleInputChange}
                            options={users?.map(item => (
                                {
                                    value : item , label : item?.name
                                }
                            ))}
                            isMulti
                            className="basic-multi-select"
                             classNamePrefix="select"
                            // inputId="input"
                            closeMenuOnSelect={false}
                            styles={ {
                                control: (provided) => ({
                                    ...provided,
                                    padding: '4px 4px' ,
                                    color : 'black'
                                }),
                                option: provided => ({
                                    ...provided,
                                    color: 'black'
                                }),
                            }}
                            onChange={setTo}
                            // onChange={(e) => {
                            //     setTo(e.value)
                            // }}
                            />
                        </div>
                {/* <div className='flex flex-col gap-4 text-[15px]'> */}
                {/* <div className='flex-1 flex flex-col gap-4'>
                                <div className='flex-1 flex flex-col gap-2'>
                                    <label className='text-[15px]'>Image *</label>
                                    <input 
                                    type='file'
                                    ref={imgRef}
                                    onChange={e => handleFileChange(e , setImage)}
                                    hidden 
                                    />
                                    <div className='flex items-center justify-between border sm:px-3 px-2 rounded-md cursor-pointer'
                                    onClick={() => (
                                        imgRef.current.click()
                                    )}
                                    > 
                                        <div>Choose File</div>
                                        <div className='py-3 sm:px-6 px-4 border-l'>Browse</div>
                                    </div>
                                </div>
                                <div className='flex-1 '>
                                    <img 
                                    src={image ? image : Img1} 
                                    alt="Category file" 
                                    className='rounded-lg w-full h-[200px] object-cover'
                                    />
                                </div>
                            </div> */}
                    
                {/* </div> */}
                <div className='mt-4' type='submit'>
                            <button className="btn-primary">
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    :
                                        'Submit'
                                }
                            </button>
                        </div>
               
            </form>
            <div className="overflow-x-auto bg-pure mt-8 mb-6 rounded-lg border shadow-md pb-20">
            <table className="w-full table-auto">
                <thead className="bg-lightSlate border-b">
                    <tr>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                           Title
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Supplier
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Products Image
                        </th>
                        {/* <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                            ACTION
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                   {
                        to?.map((item , i) => (
                            <tr className="bg-white border-b transition duration-300 ease-in-out">
                            <td className="text-sm text-gray-900 font-light px-6 py-4 w-[20px] whitespace-normal">{i+1}</td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 w-[200px] whitespace-normal">
                                {item?.value.name}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?.value.owner.firstName} {item?.value.owner.lastName}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <img 
                                    src={`${stroageBaseURL}/products/${item?.value.images[0]}`} 
                                    alt="Product" 
                                    className='w-[100px] h-[110px] object-cover rounded-md'
                                    />
                            </td>
                            {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                    DROP MENU
                                    {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                       <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                            onClick={() => deleteHandler(item._id)}
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    }
                                </div>
                            </td> */}
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
            {/* <Pagination 
            pageCount={pages}
            currentPage={currentPage}
            setPage={setCurrentPage}
            /> */}
        </div>
        </div>
    )
}

export default AddCatelog