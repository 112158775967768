import Axios from "config/api";
import { 
    setProductReviews , setPages , setCurrentPage , setDocsCount , setLoading
} from "redux/reducers/ProductReviewsReducer";

const endPoint = '/product-reviews'

export const getProductReviews = (toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { docs , pages , page , docCount } } } = await Axios(`${endPoint}?page=${getState().productReview.currentPage}`);
        dispatch(setProductReviews(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(page));
        dispatch(setDocsCount(docCount));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Products Reviews error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getSingleProductReviews = ( id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { docs , pages , page , docCount } } } = await Axios(`${endPoint}/product/${id}?page=${getState().productReview.currentPage}`);
        dispatch(setProductReviews(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(page));
        dispatch(setDocsCount(docCount));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Single Products Reviews error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}