import Axios from "config/api";
import { 
    setSupplierReviews , setPages , setCurrentPage , setDocsCount , setLoading
} from "redux/reducers/supplierReviewsReducer";

const endPoint = '/supplier-reviews'

export const getSupplierReviews = (toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { docs , pages , page , docCount } } } = await Axios(`${endPoint}?page=${getState().supplierReview.currentPage}`);
        dispatch(setSupplierReviews(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(page));
        dispatch(setDocsCount(docCount));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Products Reviews error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}