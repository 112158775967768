import ProductReportSvg from 'assets/svgs/productReport.svg';
import DatePicker_ from 'components/global/Datepicker';
import SelectBox from 'components/global/SelectBox';
import ProductReportTable from 'components/reports/ProductReportTable';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Pdf from 'assets/svgs/pdf.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, getProductReport } from 'redux/actions/productActions';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import { useReactToPrint } from 'react-to-print/lib';


const ProductReport = () => {
    const componentRef = useRef(null);
    const dispatch = useDispatch();
    const [startDate , setStartDate] = useState('');
    const [endDate , setEndDate] = useState('');
    const [product , setProduct] = useState('');
    const { 
        loading : productsLoading , products , productReportLoading , productReport 
    } = useSelector(state => state.product);

    useEffect(() => {
        dispatch(getAllProducts(toast))
    } , []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { startDate , endDate , productId : product };
        dispatch(getProductReport(data , toast));
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef?.current,
    });

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center justify-between gap-8'>
                <div className='flex items-center gap-4'>
                    <div>
                        <img 
                        src={ProductReportSvg} 
                        alt="Product Report" 
                        className='sm:w-[60px] w-[50px]'
                        />
                    </div>
                    <div className='flex flex-col gap-1'>
                        <h1 className='sm:text-2xl text-lg font-semibold'>
                            Product Report Overview
                        </h1>
                        
                    </div>
                </div>
                <div className='bg-primary text-white rounded-full px-2 py-1 cursor-pointer text-xl mr-2'>
                    <Link to='/'>
                        <i className="uil uil-estate"></i>
                    </Link>
                </div>
            </div>
            <div className='mt-6 border rounded-md shadow-sm sm:px-4 px-2 pt-2 pb-4'>
                <p className='text-grayText'>Show Data by Date Range</p>
                <div className='mt-6'>
                    <form 
                    className='flex md:items-center md:flex-row flex-col gap-6'
                    onSubmit={handleSubmit}
                    >
                        <div className='flex-1 flex flex-col gap-1.5'>
                            <label>Product</label>
                            {
                                productsLoading
                                ?
                                    <ClipLoader size={20} />
                                : 
                                products?.length > 0 
                                ? 
                                    <SelectBox 
                                    options={products?.map(item => (
                                        { 
                                            label : item?.name , 
                                            value : item?._id 
                                        }
                                    ))}
                                    value={product}
                                    setValue={setProduct}
                                    required
                                    />
                                : 
                                    <div>
                                        No product found.
                                    </div>
                            }
                        </div>
                        <div className='flex-1 flex flex-col gap-1.5'>
                            <label className='text-gray-500'>
                                From (Optional)
                            </label>
                            <DatePicker_ 
                            value={startDate} 
                            setValue={setStartDate} 
                            placeholderText='Select Date From'
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-1.5'>
                            <label className='text-gray-500'>
                                To (Optional)
                            </label>
                            <DatePicker_ 
                            value={endDate} 
                            setValue={setEndDate} 
                            placeholderText='Select Date to'
                            />
                        </div>
                        <div className='flex-[0.5]  mt-7'>
                            <button 
                            className="bg-primary py-3 rounded-md text-pure cursor-pointer hover:bg-primaryHover w-full font-semibold disabled:cursor-not-allowed"
                            type='submit'
                            disabled={!product || productReportLoading }
                            >
                                {
                                    productReportLoading
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    : 
                                        'SHOW'
                                }
                            </button>
                        </div>
                    </form>
                </div>
                {
                    productReport && Object.keys(productReport)?.length > 0 && 
                    <div className='mt-6 text-gray-500 text-sm flex flex-col gap-1 '>
                        <div className='flex items-center gap-1'>
                            <p>Product Name:</p>
                            <span>{productReport?.productName}</span>
                        </div>
                        <div className='flex items-center gap-1'>
                            <p>Total Orders:</p>
                            <span>{productReport?.totalOrders}</span>
                        </div>
                        <div className='flex items-center gap-1'>
                            <p>Total Item Qty:</p>
                            <span>{productReport?.totalSoldQty}</span>
                        </div>
                        <div className='flex items-center gap-1'>
                            <p>Total Amount:</p>
                            <span>PKR {productReport.totalAmount}</span>
                        </div>
                    </div>
                }
            </div>
            {
                productReportLoading 
                ? 
                    <Loader />
                : 
                productReport && Object.keys(productReport)?.length > 0 
                ? 
                    <div className='shadow-bg mt-6'>
                        <div className='flex items-center justify-between gap-2 sm:py-6 sm:px-5 py-4 px-2'>
                            <div className='text-lg font-semibold'>Report</div>
                            <div className='cursor-pointer' title='Export to pdf'>
                                <img 
                                src={Pdf} 
                                alt="Pdf" 
                                className='w-[40px]' 
                                onClick={handlePrint}
                                />
                            </div>
                        </div>
                        <ProductReportTable 
                        ref={componentRef}
                        />
                    </div>
                : 
                    <ItemNotFound />

            }
        </div>
    )
}

export default ProductReport