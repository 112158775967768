import ItemNotFound from "components/global/ItemNotFound";
import { stroageBaseURL } from "config/api";
import { useSelector } from "react-redux";

const TopCustomers = () => {
    const { stats : { topCustomers } } = useSelector(state => state.dashboard);

    return (
        <div>
            <div className='py-4 px-4 flex items-center justify-between border-b'>
                <div className='flex items-center gap-2'>
                    <i className="uil uil-users-alt text-xl"></i>
                    <h4 className='font-semibold'>Top Customers</h4>
                </div>
                <div className='text-5xl text-grayText'>
                    <i className="uil uil-user-circle"></i>
                </div>
            </div>
            {
                topCustomers?.length > 0
                ? 
                    <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 py-8 px-4'>
                        {
                            topCustomers?.map((item , i) => (
                                <div 
                                className='border-2 rounded-lg py-2 px-4 flex flex-col gap-4' 
                                key={i}
                                >
                                    <div className='bg-orange-600 w-fit text-pure rounded-md py-1 px-4 text-[10px]'>Orders {item?.count}</div>
                                    <div className='w-[60px] h-[60px] mx-auto'>
                                        <img 
                                        src={stroageBaseURL + '/users/' + item?.user?.image} alt={item?.user?.firstName + item?.user?.lastName} 
                                        className='w-full h-full object-cover rounded-full border border-grayText'/>
                                    </div>
                                    <div className='text-center text-sm text-grayText'>
                                        {item?.user?.firstName + item?.user?.lastName}
                                    </div>
                                    
                                </div>
                            ))
                        }
                    </div>
                : 
                    <div className="h-[200px] flex items-center justify-center text-xl font-semibold">
                        No item found.
                    </div>
            }
        </div>
    )
}

export default TopCustomers