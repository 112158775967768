import { createSlice } from "@reduxjs/toolkit";


const dashboardSlice = createSlice({
    name : 'dashboard' ,
    initialState : {
        stats : {} ,
        loading : false ,
        error : null 
    } , 
    reducers : {
        setDashboardStats (state , action) {
            state.stats = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setError (state , action) {
            state.error = action.payload;
        } , 
        clearError (state , action) {
            state.error = null ;
        }
    }
});

export const { setDashboardStats , setLoading , setError , clearError } = dashboardSlice.actions;

export default dashboardSlice.reducer;


