import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import Search from 'components/global/Search';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getProductReviews } from 'redux/actions/ProductReviewsActions';
import { setCurrentPage } from 'redux/reducers/ProductReviewsReducer';

const ProductReviews = () => {
    const dispatch = useDispatch();
    const { reviews , loading , currentPage , pages , docsCount } = useSelector(state => state.productReview);

    useEffect(() => {
        dispatch(getProductReviews(toast));
    }, [currentPage])

    return (
        <div className='sm:p-6 py-4 px-2'>
            <h1 className='main-heading'>Review List <span className='text-primary'>{docsCount}</span></h1>
            {
                loading 
                ? 
                    <Loader />
                : 
                reviews?.length > 0 
                ? 
                    <div className='shadow-bg mt-6'>
                        <div className='pb-12 overflow-x-auto'>
                        
                            <table className="w-full table-auto overflow-x-auto">
                                <thead className="bg-lightSlate border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            #
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            PRODUCT
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            CUSTOMER
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            REVIEW
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-right">
                                            RATING
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    reviews?.map((item , i) => (
                                        <tr className="bg-white border-b transition duration-300 ease-in-out">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {i+1}
                                        </td>
                                        <td className="text-sm text-primary px-6 py-4 whitespace-nowrap font-normal">
                                            <Link to={`/product/details/${i+1}`}>
                                                {item?.product?.name}
                                            </Link>
                                        </td>
                                        <td className="text-sm text-primary px-6 py-4 whitespace-nowrap font-medium">
                                            <Link to={`/customer/${item?.user?._id}`}>
                                                {
                                                    item?.user?.firstName 
                                                    + " " + 
                                                    item?.user?.lastName
                                                }
                                            </Link>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                            {item?.review}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                                            <div className='flex justify-end'>
                                                <div className='bg-cyan-100 text-cyan-500 w-fit px-2 rounded-md flex items-center gap-1'>
                                                    <span>{item?.rating}</span>
                                                    <i className="uil uil-star"></i>
                                                </div>
                                            </div>
                                        </td>
                                        </tr>
                                    ))
                                }
                                
                                </tbody>
                            </table> 
                            <Pagination
                            pageCount={pages}
                            currentPage={currentPage}
                            setPage={setCurrentPage}
                            />
                        </div>
                    </div>
                : 
                    <ItemNotFound />

            }
        </div>
    )
}

export default ProductReviews;