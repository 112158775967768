import React, { useEffect, useRef, useState } from 'react'
import SettingsSvg from '../../assets/svgs/SettingsSvg'
import DownArrow from '../../assets/svgs/DownArrow'
import View from '../../assets/svgs/View'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../../context/context'
import useClickOutside from '../../utils/clickOutside';
import { useSelector , useDispatch } from 'react-redux';
// import { getWithdrawRequests } from '../../../redux/actions/withdrawActions'
import { setCurrentPage } from '../../redux/reducers/withdrawReducer';
import Pagination from '../../components/global/pagination';
import moment from 'moment'
import PaymentStatus from '../../components/global/PaymentStatus'
import EditWithdrawRequest from './EditWithdrawRequest'
// import Cookies from 'js-cookie';
import useToggle from 'hooks/useToggle';


const WithdrawRequestTable = () => {
    const dispatch = useDispatch();
    // const { openModal } = useGlobalContext()
    const [showAddSubCatPopup , toggleAddSubCatPopup] = useToggle(false);
    const [editItem , setEditItem] = useState(null);

    const [isView, setIsView] = useState(false)
    const [isIndex, setIsIndex] = useState(null)
    const viewRef = useRef(null)

    const { requests , pages  , currentPage } = useSelector(state => state.withdraw);

    const viewIndex = (index) => {
        setIsIndex(index)
        setIsView(true)
    }
    const editHandler = (e,item) => {
        e.stopPropagation();
        setIsView(false)
        setEditItem(item);
        toggleAddSubCatPopup(true);
    }
    useClickOutside(viewRef, () => setIsView(false));

    const handleViewClick = (e , item) => {
        e.stopPropagation();
        setIsView(false)
        localStorage.setItem('selectedRequest' , JSON.stringify(item));
        // openModal();
        
    }



    return (
        <div className='overflow-x-auto bg-pure  border  pb-20'>

        <table className='w-full table-auto'>
            <thead className='bg-lightSlate border-b'>
                <tr >
                    <th className='py-4 px-3 font-semibold text-[13px] text-center whitespace-nowrap'>
                        #
                    </th>
                    <th className='py-4 px-3 font-semibold text-[13px] text-center whitespace-nowrap'>
                        NAME/No.
                    </th>
                    <th className='py-4 px-3 font-semibold text-[13px] text-center whitespace-nowrap'>
                        Date
                    </th>
                    <th className='py-4 px-3 font-semibold text-[13px] text-center whitespace-nowrap'>
                        AMOUNT
                    </th>
                    <th className='py-4 px-3 font-semibold text-[13px] text-center whitespace-nowrap'>
                        STATUS
                    </th>
                    <th className='py-4 px-3 font-semibold text-[13px] text-center whitespace-nowrap'>
                        ACTIONS
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    requests?.map((item, index) => {
                        return (
                            <tr key={index} className='border-b'>
                                <td className='text-center px-5 whitespace-nowrap text-sm font-medium py-4'>{index+1}</td>
                                <td className='text-center px-5 whitespace-nowrap text-sm font-medium py-2 '>
                                    <p className='flex flex-col'>
                                        <span>{item?.accountOwnerName}</span>
                                        <span>{item?.accountNumber}</span>
                                        {/* <small>
                                            {moment(item?.createdAt).format('DD/MM/YYYY')}
                                        </small> */}
                                    </p>
                                </td>
                                <td className='text-center px-5 whitespace-nowrap text-sm font-medium py-4'>
                                {moment(item?.createdAt).format('DD/MM/YYYY')}
                                </td>
                                <td className='text-center px-5 whitespace-nowrap text-sm font-medium py-4'>
                                    RS: {item?.withdrawAmount}
                                </td>

                                <td className='text-center px-5 whitespace-nowrap py-4 text-sm font-medium '>
                                    <div className='flex items-center justify-center'>
                                    <PaymentStatus status={item?.status} />
                                    </div>
                                </td>
                                <td className='text-center py-4 flex justify-center px-5 whitespace-nowrap'>
                                    <div onClick={() => viewIndex(index)} className='h-full  flex items-center justify-center gap-3 rounded-lg py-1 px-2 border gradient-3 cursor-pointer relative'>
                                        <SettingsSvg />
                                        <DownArrow />
                                        {
                                            isView && isIndex === index &&
                                            <div ref={viewRef} onClick={(e) => editHandler( e,item)}
                                             className='absolute left-1/2 -translate-x-[50%] top-full rounded-xl text-sm font-medium bg-white text-black px-4 py-4 z-30 flex items-center gap-1 '
                                            >
                                                <View />
                                                <span>Edit</span>
                                            </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
            <Pagination 
            currentPage={currentPage}
            setPage={setCurrentPage}
            pageCount={pages}
            />
        </table>

        {
                showAddSubCatPopup && 
                <div>
                    <EditWithdrawRequest 
                    toggleAddSubCatPopup={toggleAddSubCatPopup}
                    editItem={editItem}
                    setEditItem={setEditItem}
                    />
                </div>
            }
        </div>
    )
}

export default WithdrawRequestTable
