import { createSlice } from "@reduxjs/toolkit";


const productReviewSlice = createSlice({
    name : 'productReview' ,
    initialState : {
        reviews : [] ,
        loading : false ,
        currentPage : 1 ,
        pages : 1 ,
        docsCount : 1
    } ,
    reducers : {
        setProductReviews (state , action) {
            state.reviews = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } , 
        setCurrentPage (state , action){
            state.currentPage = action.payload;
        } , 
        setPages (state , action){
            state.pages = action.payload;
        } ,
    }
});

export const { 
    setLoading , setProductReviews , setCurrentPage , setPages , setDocsCount
} = productReviewSlice.actions;

export default productReviewSlice.reducer;