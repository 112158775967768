import React from 'react'

const View = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1668 6.09995C12.6268 3.67995 10.3735 2.28662 8.00016 2.28662C6.8135 2.28662 5.66016 2.63329 4.60683 3.27995C3.5535 3.93329 2.60683 4.88662 1.8335 6.09995C1.16683 7.14662 1.16683 8.84662 1.8335 9.89329C3.3735 12.32 5.62683 13.7066 8.00016 13.7066C9.18683 13.7066 10.3402 13.36 11.3935 12.7133C12.4468 12.06 13.3935 11.1066 14.1668 9.89329C14.8335 8.85329 14.8335 7.14662 14.1668 6.09995ZM8.00016 10.6933C6.50683 10.6933 5.30683 9.48662 5.30683 7.99995C5.30683 6.51329 6.50683 5.30662 8.00016 5.30662C9.4935 5.30662 10.6935 6.51329 10.6935 7.99995C10.6935 9.48662 9.4935 10.6933 8.00016 10.6933Z" fill="#8A080C" />
            <path d="M7.99855 6.09338C7.49375 6.09338 7.00963 6.29391 6.65269 6.65086C6.29574 7.0078 6.09521 7.49192 6.09521 7.99672C6.09521 8.50151 6.29574 8.98563 6.65269 9.34258C7.00963 9.69952 7.49375 9.90005 7.99855 9.90005C9.04522 9.90005 9.90522 9.04672 9.90522 8.00005C9.90522 6.95338 9.04522 6.09338 7.99855 6.09338Z" fill="#8A080C" />
        </svg>


    )
}

export default View
