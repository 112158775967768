import Heading from 'components/global/Heading';
import SelectBox from 'components/global/SelectBox';
import Img1 from 'assets/images/img1.jpg';
import { useEffect, useRef, useState } from 'react';
import BackBtn from 'components/global/BackBtn';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createSupplier, _updateSupplier } from 'redux/actions/supplierActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Axios, { stroageBaseURL } from 'config/api';
import isBase64 from 'utils/isBase64';
import axios from 'axios';
import Select from 'react-select';
import debounce from 'lodash.debounce';

const SupplierRegister = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const edit = searchParams.get('edit');
    const supplierId = searchParams.get('supplier');
    let editItem;
    if(edit){
        editItem = JSON.parse(localStorage.getItem('editSupplier'))
    }else {
        localStorage.removeItem('editSupplier');
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const coverImageRef = useRef();
    const supplierImgRef = useRef();

    const { createLoading , updateLoading } = useSelector(state => state.supplier);

    const [citiesLoading , setCitiesLoading] = useState(false);
    const [cities , setCities] = useState([]);
    const [data , setData] = useState({
        firstName : editItem?.firstName || '' ,
        lastName : editItem?.lastName || '' ,
        email : editItem?.email || '' ,
        password : '' ,
        phone : editItem?.phone || '' ,
        identityNumber : editItem?.identityNumber || '' ,
        address : editItem?.address || '' ,
        cityName : editItem?.cityName || '' ,
        cityId : editItem?.cityId || ''
    });
    const [image , setImage] = useState( 
        edit ? `${stroageBaseURL}/suppliers/${editItem?.image}` : ''
    );
    const [coverImage , setCoverImage] = useState(
        edit ? `${stroageBaseURL}/supplierCovers/${editItem?.coverImage}` : ''
    );


    const handleFileChange = (e , setValue) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setValue(reader.result);
        }
    }


    const handleChange = e => {
        const { name , value } = e.target;
        setData(prev => ({...prev , [name] : value }));
    }
    
    const submitHandler = async e => {
        e.preventDefault();
        if(edit){
            if(isBase64(image)) data.image = image;
            if(isBase64(coverImage)) data.coverImage = coverImage;
            delete data.email;
            delete data.password;
            await dispatch(_updateSupplier(supplierId , data , toast , navigate));
            localStorage.removeItem('editSupplier');
        }else {
            if(!coverImage || !image || !data?.password) toast.error('All fiedls are required.');
            data.image = image;
            data.coverImage = coverImage;
            console.log(data);
            dispatch(createSupplier(data , toast , navigate));
        }
    }

    const searchCities = debounce(async (inputValue) => {
        try {
            setCitiesLoading(true);
            const { data : { data : { docs } } } = await Axios(`/admin/get/all-cities?keyword=${inputValue}`);
            const cities = docs?.map((item) => ({ value: item.operationalCityName , label: item.operationalCityName }));
            setCities(cities);
            setCitiesLoading(false);
        } catch (error) {
            setCitiesLoading(false);
            console.log('Fetch citites error:' , error);
            toast.error('Something went wrong.')
        }
    }, 500);

    const handleCityInputChange = (value) => {
        searchCities(value);
    };

    const handleCityChange = (e) => {
        setData(prev => ({...prev , cityName : e.label , cityId : e.value }))
    }

    return (
        <div className='sm:p-6 px-2 py-4'>
             <div className="flex items-center justify-between">
                <Heading title='Add New Supplier' />
                <BackBtn />
            </div>
            <form 
            className="flex flex-col gap-6"
            onSubmit={submitHandler}
            >
                <div className='shadow-bg sm:py-6 py-4 sm:px-6 px-2 mt-6 flex flex-col gap-6'>
                    <div className="flex gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>First Name</label>
                            <input 
                            type="text" 
                            placeholder='First Name'
                            className='textField'
                            value={data?.firstName}
                            name='firstName'
                            onChange={handleChange}
                            required
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Last Name</label>
                            <input 
                            type="text" 
                            placeholder='Last Name'
                            className='textField'
                            value={data?.lastName}
                            name='lastName'
                            onChange={handleChange}
                            required
                            />
                        </div>
                    </div>
                    <div className="flex gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Email</label>
                            <input 
                            type="text" 
                            placeholder='Ex : ex@example.com'
                            className='textField'
                            value={data?.email}
                            name='email'
                            onChange={handleChange}
                            required
                            readOnly={edit}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Phone</label>
                            <input 
                            type="text" 
                            placeholder='Ex : 017*********'
                            className='textField'
                            value={data?.phone}
                            name='phone'
                            onChange={handleChange}
                            required
                            />
                        </div>
                       
                    </div>
                    <div className="flex gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <label className='text-[15px]'>City</label>
                                {
                                    citiesLoading && 
                                    <div>
                                        <ClipLoader size={15} />
                                    </div>
                                }
                            </div>
                            <Select
                                options={cities}
                                className='outline-none focus:border-red-500'
                                inputId='select-box-input'
                                onInputChange={handleCityInputChange}
                                onChange={handleCityChange}
                                placeholder='Search your city name'
                                value={{ label : data?.cityName , value : data?.cityId}}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Address</label>
                            <input 
                            type="text" 
                            placeholder='Ex : Model Town Block B Streent no 4'
                            className='textField'
                            value={data?.address}
                            name='address'
                            onChange={handleChange}
                            required
                            />
                        </div>
                       
                    </div>
                    <div className="flex gap-4 sm:flex-row flex-col">
                        <div className="flex-1 flex flex-col gap-2">
                            <label className='text-[15px]'>Password</label>
                            <input 
                            type="password" 
                            placeholder='Ex : password'
                            className='textField'
                            value={data?.password}
                            name='password'
                            onChange={handleChange}
                            readOnly={edit}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Identity Number</label>
                            <input 
                            type="text" 
                            placeholder='Ex : DH-2380-9823'
                            className='textField'
                            value={data?.identityNumber}
                            name='identityNumber'
                            onChange={handleChange}
                            required
                            />
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div className='flex-1'>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Cover Image *</label>
                                <input 
                                type="file" 
                                ref={coverImageRef}
                                onChange={e => handleFileChange(e , setCoverImage)}
                                hidden
                                />
                                <div className='flex items-center justify-between border px-3 rounded-md cursor-pointer'
                                onClick={() => (
                                    coverImageRef?.current?.click()
                                )}
                                > 
                                    <div>Choose File</div>
                                    <div className='py-3 px-6 border-l'>Browse</div>
                                </div>
                            </div>
                            <div className='mt-4 border-2 border-dashed w-fit'>
                                <img 
                                src={coverImage || Img1} alt="Supplier id front image" className='w-[350px] h-[200px] object-cover'
                                />
                            </div>
                        </div>
                        <div className='flex-1'>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Supplier Image *</label>
                                <input 
                                type="file" 
                                ref={supplierImgRef}
                                onChange={e => handleFileChange(e , setImage)}
                                hidden
                                />
                                <div className='flex items-center justify-between border px-3 rounded-md cursor-pointer'
                                onClick={() => (
                                    supplierImgRef?.current?.click()
                                )}
                                > 
                                    <div>Choose File</div>
                                    <div className='py-3 px-6 border-l'>Browse</div>
                                </div>
                            </div>
                            <div className='mt-4 border-2 border-dashed w-fit' onClick={() => supplierImgRef?.current?.click()}>
                                <img src={image || Img1} alt="" className='w-[350px] h-[200px] object-cover' />
                            </div>
                        </div>
                       
                    </div>
                
                    <div>
                        <button 
                        className="btn-primary"
                        disabled={createLoading || updateLoading}
                        >
                            {
                                createLoading || updateLoading
                                ? 
                                    <ClipLoader size={20} color='#fff' />
                                : 
                                    'SUBMIT'
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SupplierRegister;