import Heading from 'components/global/Heading';
import NotificationsTable from 'components/notifications/NotificationsTable';
import React from 'react'
import { Link } from 'react-router-dom';

const Notifications = () => {
    return (
        <div className='sm:p-6 py-4 px-2'>
            <Heading title="Notifications" icon={false} />
            <div className='shadow-bg mt-6'>
                <div className='flex items-center justify-end sm:p-5 px-2 py-4'>
                    <Link to='/send-notification'>
                        <button className="btn-primary">
                            <i className="uil uil-plus-circle"></i>
                            <span>Send Notification</span>
                        </button>
                    </Link>
                </div>
                <div>
                    <NotificationsTable />
                </div>
            </div>
        </div>
    )
}

export default Notifications;