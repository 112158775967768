import './styles.css';
import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';


const Sidebar = () => {
    const sidebarRef = useRef();
    const location = useLocation();
    const [showCatDrop , setShowCatDrop] = useState(false);
    const [showSettingsDrop , setShowSettingsDrop] = useState(false);
    const [showReportDropMenu , setShowReportDropMenu] = useState(false);
    const { showDrawer , setShowDrawer } = useDrawerContext();

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if(home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

    return (
        <div>
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0 md:left-0 -left-[200%] w-[250px] min-h-screen h-full overflow-auto py-4 px-6 z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='overflow-auto pb-8'>
                    <Link to='/' className='text-3xl font-bold text-primary'>
                        LOGO
                    </Link>
                    <ul className='sideMenu-list mt-6 text-dark min-h-screen h-full'>
                        <li>
                            <Link 
                            to='/' 
                            className={`${isActive('' , true) ? 'active' : ''} sideMenu-item`}
                            >   
                                <i className="uil uil-home"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <p className='sideMenu-heading'>ORDER SECTION</p>
                            <Link 
                            to='/orders' 
                            className={`${isActive('orders') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-shopping-cart"></i>
                                <span>Orders</span>
                                
                            </Link>
                        </li>
                        {/* Product Section */}
                        <li>
                            <p className='sideMenu-heading'>PRODUCT SECTION</p>
                            <div 
                            className={`${isActive('categories') ? 'active' : ''} sideMenu-item`}
                            onClick={() => setShowCatDrop(prev => !prev)}
                            >
                                <i className="uil uil-sitemap"></i>
                                <span>Category</span>
                                <div className='sideMenu-item-angle'>
                                    <i className="uil uil-angle-down"></i>
                                </div>
                            </div>
                            {/* DROP MENU */}
                            {
                                showCatDrop && 
                                <ul className="sideMenu-dropList">
                                    <li>
                                        <Link 
                                        to='/categories/main' 
                                        className={`${isActive('main') ? 'active' : ''} sideMenu-item`}
                                        >
                                            <div className='sideMenu-dropCircle'></div>
                                            <span>Main Category</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                        to='/categories/sub' 
                                        className={`${isActive('sub') ? 'active' : ''} sideMenu-item`}
                                        >
                                            <div className='sideMenu-dropCircle'></div>
                                            <span>Sub Category</span>
                                        </Link>
                                    </li>
                                </ul>
                            }
                        </li>
                        <li>
                            <Link 
                            to='/attribute' 
                            className={`${isActive('attribute') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-chart-pie-alt"></i>
                                <span>Attribute</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/products' 
                            className={`${isActive('products') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-diamond"></i>
                                <span>Products</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/banners' 
                            className={`${isActive('banners') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-scenery"></i>
                                <span>Banner</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/text-banner' 
                            className={`${isActive('text-banner') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-file-exclamation-alt"></i>
                                <span>Text Banner</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/catelog' 
                            className={`${isActive('catelog') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-file-exclamation-alt"></i>
                                <span>Catelog</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/section' 
                            className={`${isActive('section') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-file-exclamation-alt"></i>
                                <span>Section</span>
                                
                            </Link>
                        </li>
                        {/* Business Section */}
                        <li>
                            <p className='sideMenu-heading'>BUSINESS SECTION</p>
                            <Link 
                            to='/messages' 
                            className={`${isActive('messages') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-envelopes"></i>
                                <span>Messages</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/product-reviews' 
                            className={`${isActive('product-reviews') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-star"></i>
                                <span>Product Reviews</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/notifications' 
                            className={`${isActive('notifications') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-bell"></i>
                                <span>Notifications</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/withdrawRequests' 
                            className={`${isActive('withdrawRequests') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-bell"></i>
                                <span>Withdraw Requests</span>
                                
                            </Link>
                        </li>
                        <li>
                            <div 
                            className={`${isActive('settings') ? 'active' : ''} sideMenu-item`}
                            onClick={() => setShowSettingsDrop(prev => !prev)}
                            >
                                <i className="uil uil-setting"></i>
                                <span>Settings</span>
                                <div className='sideMenu-item-angle'>
                                    <i className="uil uil-angle-down"></i>
                                </div>
                            </div>
                        </li>
                         {/* DROP MENU */}
                         {
                            showSettingsDrop && 
                            <ul className="sideMenu-dropList -mt-4">
                                <li>
                                    <Link 
                                    to='/settings/ecommerce-setup' 
                                    className={`${isActive('ecommerce-setup') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Ecommerce Setup</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/social-media' 
                                    className={`${isActive('social-media') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Social Media</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/return-policy' 
                                    className={`${isActive('return-policy') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Return Policy</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/refund-Policy' 
                                    className={`${isActive('refund-Policy') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Refund Policy</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/cancellation-policy' 
                                    className={`${isActive('cancellation-policy') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Cacellation Policy</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/Terms-and-condition' 
                                    className={`${isActive('Terms-and-condition') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Terms And Condition</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/privacy-policy' 
                                    className={`${isActive('privacy-policy') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>Privacy Policy</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                    to='/settings/about' 
                                    className={`${isActive('about') ? 'active' : ''} sideMenu-item`}
                                    >
                                        <div className='sideMenu-dropCircle'></div>
                                        <span>About Us</span>
                                    </Link>
                                </li>
                            </ul>
                        }
                        {/* Suppliers Man Section */}
                        <li>
                            <p className='sideMenu-heading'>SUPPLIERS SECTION</p>
                            <Link 
                            to='/supplier/register' 
                            className={`${isActive('register') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-accessible-icon-alt"></i>
                                <span>Register</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/supplier/list' 
                            className={`${isActive('list') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-list-ul"></i>
                                <span>List</span>
                                
                            </Link>
                        </li>
                        <li>
                            <Link 
                            to='/supplier/reviews' 
                            className={`${isActive('reviews') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-star"></i>
                                <span>Reviews</span>
                                
                            </Link>
                        </li>
                        {/* Customer Section */}
                        <li>
                            <p className='sideMenu-heading'>Customer SECTION</p>
                            <Link 
                            to='/customer-list' 
                            className={`${isActive('customer-list') ? 'active' : ''} sideMenu-item`}
                            >
                                <i className="uil uil-user"></i>
                                <span>Customer List</span>
                            </Link>
                        </li>
                         {/* Report Section */}
                         <li>
                            <p className='sideMenu-heading'>REPORT</p>
                            <div 
                            className={`${isActive('report') ? 'active' : ''} sideMenu-item`}
                            onClick={() => setShowReportDropMenu(prev => !prev)}
                            >
                                <i className="uil uil-ban"></i>
                                <span>Report</span>
                                <div className='sideMenu-item-angle'>
                                    <i className="uil uil-angle-down"></i>
                                </div>
                            </div>
                            {/* DROP MENU */}
                            {
                                showReportDropMenu && 
                                <ul className="sideMenu-dropList">
                                    <li>
                                        <Link 
                                        to='/report/earning-report' 
                                        className={`${isActive('earning-report') ? 'active' : ''} sideMenu-item`}
                                        >
                                            <div className='sideMenu-dropCircle'></div>
                                            <span>Earning Report</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                        to='/report/product-report' 
                                        className={`${isActive('product-report') ? 'active' : ''} sideMenu-item`}
                                        >
                                            <div className='sideMenu-dropCircle'></div>
                                            <span>Product Report</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                        to='/report/supplier-report' 
                                        className={`${isActive('supplier-report') ? 'active' : ''} sideMenu-item`}
                                        >
                                            <div className='sideMenu-dropCircle'></div>
                                            <span>Supplier Report</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                        to='/report/generated-loadsheet' 
                                        className={`${isActive('generated-loadsheet') ? 'active' : ''} sideMenu-item`}
                                        >
                                            <div className='sideMenu-dropCircle'></div>
                                            <span>Generated Load Sheets</span>
                                        </Link>
                                    </li>
                                </ul>
                            }
                        </li>
                    </ul>
                </div>
                

            </div>
        </div>
    )
}

export default Sidebar