import OrdersTable from 'components/customersList/OrdersTable';
import UserDetailsCard from 'components/customersList/UserDetailsCard';
import BackBtn from 'components/global/BackBtn';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCustomerDetails } from 'redux/actions/customerActions';
import { getSingleUserOrders } from 'redux/actions/orderActions';

const CustomerDetails = () => {
    const params = useParams();
    const { id } = params;
    const dispatch = useDispatch();
    const { loading : customerDetailLoading , customerDetails } = useSelector(state => state.customer);
    const { loading : ordersLoading , orders } = useSelector(state => state.order);

    useEffect(() => {
        dispatch(getCustomerDetails(id , toast));
    }, [])

    useEffect(() => {
        dispatch(getSingleUserOrders(id , toast));
    }, []);

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center justify-between'>
                <div>
                    <h6 className='text-xl font-semibold'>Customer Id #{id}</h6>
                </div>
                <div>
                    <BackBtn />
                </div>
            </div>
           
            <div className='flex lg:flex-row flex-col-reverse gap-6 mt-12'>
                <div className='flex-[0.7] shadow-bg'>
                    {
                        ordersLoading
                        ? 
                            <Loader />
                        : 
                        orders?.length > 0 
                        ? 
                            <OrdersTable />
                        : 
                            <ItemNotFound />
                    }
                </div>
                <div className='flex-[0.3]'>
                    {
                        customerDetailLoading 
                        ? 
                            <Loader />
                        : 
                        Object.keys(customerDetails)?.length > 0 
                        ? 
                            <UserDetailsCard user={customerDetails} />
                        : 
                            <ItemNotFound />
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails;