import React, { useEffect, useRef, useState } from 'react';
import Img1 from 'assets/images/img1.jpg';
import useClickOutside from 'utils/clickOutside';
import { getTotalCategories } from 'redux/actions/categoryActions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { createSubCategory, updateSubCat } from 'redux/actions/subCategoryActions';
import FileInput from 'components/global/FileInput';
import { stroageBaseURL } from 'config/api';
import isBase64 from 'utils/isBase64';
import { updateWithdrawRequest } from 'redux/actions/withdrawActions';


const EditWithdrawRequest = ({ toggleAddSubCatPopup , setEditItem , editItem }) => {
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [accountOwnerName , setaccountOwnerName] = useState( editItem?.accountOwnerName || '');
    const [accountNumber , setaccountNumber] = useState( editItem?.accountNumber || '');
    const [withdrawAmount , setwithdrawAmount] = useState( editItem?.withdrawAmount || '');
    const [description , setdescription] = useState(editItem?.description || '');
    const [status , setstatus] = useState(editItem?.status|| 0);
    // const { categories , loading } = useSelector(state => state.category);
    const [image , setImage] = useState(editItem ? stroageBaseURL + '/PaymentProofs/' + editItem?.paymentProof : null);
    const [categories , setcategories] = useState( [{id:0,name:"Pending"},{id:1,name:"Paid"},{id:2,name:"Decline"},]);
    

    const { createLoading , updateLoading } = useSelector(state => state.subCategory);
    // const [mainCategory , setMainCategory] = useState(editItem?.mainCategory?._id || '');
    

    useClickOutside(formRef , () =>  {
        toggleAddSubCatPopup();
        setEditItem(null);
        setImage(null);
    })

    useEffect(() => {
        dispatch(getTotalCategories(toast))
    } , []);

    const handleMainCatChange = (e) => {
        setstatus(e.target.value);
    }

    const submitHandler = async e => {
        e.preventDefault();
        const data = {  description,status };
        if(isBase64(image)) {
            data.paymentProof = image;
        }
        if(editItem){
            await dispatch(updateWithdrawRequest(editItem?._id , data , toast))
        }
        // else {
        //     await dispatch(createSubCategory(data , toast));
        // }
        // setName('');
        // setMainCategory('');
        setImage(null);
        toggleAddSubCatPopup(false);
        setEditItem(null);

    }

    return (
        <div className='fixed top-0 left-0 w-full min-h-screen bg-[#00000063] flex items-center justify-center z-[9999]'
        
        >
            <div className='md:w-[70%] w-[95%] bg-pure rounded-lg border shadow-lg py-4 px-4'
            ref={formRef}
            >
                <div className='flex items-center justify-between pb-3 border-b'>
                    <div className='flex items-center gap-2 '>
                        <div className='text-lg'>
                            <i className="uil uil-plus-circle"></i>
                        </div>
                        <h3 className='font-semibold'>Edit Payment Request</h3>
                    </div>
                    <div className='text-xl cursor-pointer' onClick={() => {
                        toggleAddSubCatPopup(false);
                        setEditItem(null);
                        setImage(null);
                    }} >
                        <i className="uil uil-times"></i>
                    </div>
                </div>
                <div className='mt-8'> 
                    <form 
                    className='flex flex-col gap-4' 
                    onSubmit={submitHandler}
                    >
                          <div className='flex md:items-center justify-between gap-4 md:flex-row flex-col'>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]' htmlFor="pName" >Withdraw Account Holder</label>
                                <input 
                                id='pName'
                                type="text" 
                                placeholder='Withdraw Account Holder'
                                className='textField'
                                value={accountOwnerName}
                                // onChange={e => setaccountOwnerName(e.target.value)}
                                readOnly
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]' htmlFor="pName" >Withdraw Account No.</label>
                                <input 
                                id='pName'
                                type="text" 
                                placeholder='Withdraw Account No.'
                                className='textField'
                                value={accountNumber}
                                // onChange={e => setaccountNumber(e.target.value)}
                                readOnly
                                />
                            </div>
                            
                        </div>
                        <div className='flex md:items-center justify-between gap-4 md:flex-row flex-col'>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]' htmlFor="pName" >Withdraw Amount</label>
                                <input 
                                id='pName'
                                type="text" 
                                placeholder='Withdraw Amount'
                                className='textField'
                                value={withdrawAmount}
                                // onChange={e => setaccountOwnerName(e.target.value)}
                                readOnly
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]' htmlFor="pName" >Status</label>
                                {
                                    
                                    categories?.length > 0 
                                    ? 
                                        <select className='textField'
                                        onChange={handleMainCatChange}
                                        required
                                        >
                                            <option value={status}>Select</option>
                                            {
                                                categories?.map(cat => (
                                                    <option 
                                                    value={cat?.id}
                                                    key={cat?.id}
                                                    >
                                                        {cat?.name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    : 
                                        <div>
                                            No item found.
                                        </div>
                                }
                            </div>
                        </div>
                        <div className='flex md:items-center justify-between gap-4 md:flex-row flex-col'>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]' htmlFor="pName" >Description</label>
                                <input 
                                id='pName'
                                type="text" 
                                placeholder='Description'
                                className='textField'
                                value={description}
                                onChange={e => setdescription(e.target.value)}
                                required
                                />
                            </div>
                            {/* <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]' htmlFor="pName" >Withdraw Account No.</label>
                                <input 
                                id='pName'
                                type="text" 
                                placeholder='Withdraw Account No.'
                                className='textField'
                                value={accountNumber}
                                // onChange={e => setaccountNumber(e.target.value)}
                                readOnly
                                />
                            </div> */}
                            
                        </div>
                        <div>
                            <FileInput
                            label='Image'
                            value={image}
                            setValue={setImage}
                            />
                        </div>
                        <div className='mt-4' type='submit'>
                            <button 
                            className="btn-primary disabled:cursor-not-allowed"
                            disabled={ createLoading || updateLoading}
                            >
                                {
                                    createLoading || updateLoading
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    : 
                                        'Submit'
                                }
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default EditWithdrawRequest