import MultiSelectBox from "components/products/MultiSelectBox";
import SelectBox from "components/global/SelectBox";
import { useEffect, useRef, useState } from "react";
import BackBtn from "components/global/BackBtn";
import Heading from "components/global/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getTotalAttributes } from "redux/actions/attributeActions";
import { toast } from "react-toastify";
import { getSubCategories, getTotalCategories } from "redux/actions/categoryActions";
import { ClipLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import { getSuppliers } from "redux/actions/supplierActions";
import Axios, { stroageBaseURL } from "config/api";
import { removeEditItemImg } from "redux/reducers/productReducer";
import { _approveProduct, _updateProduct } from "redux/actions/productActions";



const EditProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const prodImgRef = useRef(null);
    const { id } = useParams();

    const { updateLoading , editItem } = useSelector(state => state.product);

    const [selectedAttributes , setselectedAttributes] = useState(editItem?.attributes.map(item => ({...item , label : item.name })));
    const [images , setImages] = useState([]);
    const [productStatusValue , setProductStatusValue] = useState(`${editItem?.status}` || '');
    const [category , setCategory] = useState(editItem?.category?._id || '');
    const [subCategory , setSubCategory] = useState(editItem?.subCategory?._id || '');
    const [name , setName] = useState(editItem?.name || '');
    const [description , setDescription] = useState(editItem?.description || '');
    const [price , setPrice] = useState(editItem?.price || '');
    const [unit , setUnit] = useState(editItem?.unit || '');
    const [discount , setDiscount] = useState(editItem?.discount || '');
    const [discountType , setDiscountType] = useState(editItem?.discountType || '');
    const [stock , setStock] = useState(editItem?.stock || '');
    const [supplier , setSupplier] = useState(editItem?.owner?._id || '');


    // Store States 
    const { attributes } = useSelector(state => state.attributes);
    const { categories , subCategories , loading } = useSelector(state => state.category);
    const { suppliers , loading : supplierLoading } = useSelector(state => state.supplier);
    const { user } = useSelector(state => state.auth);

    const handleAttrChange = (e) => {
        const { name, value } = e.target;
        setselectedAttributes(prev => {
            const index = prev.findIndex(obj => obj.name === name);
            const updatedObj = { ...prev[index] , value };
            return [...prev.slice(0, index), updatedObj , ...prev.slice(index + 1)];
        });
    }
      
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImages(prev => [reader.result , ...prev]);
        };
    };

    useEffect(() => {
        dispatch(getTotalAttributes(toast));
        dispatch(getTotalCategories());
        dispatch(getSuppliers(toast))
    }, []);

    useEffect(() => {
        if(!category) return ;
        dispatch(getSubCategories(category , toast));
    }, [category]);

    

    const submitHandler = async e => {
        e.preventDefault();
        const data = {
            name , description , price , unit , stock , discount , discountType ,
            attributes : selectedAttributes ,
            category , subCategory , images , owner : supplier
        } ;
       dispatch(_updateProduct(editItem?._id , data , toast , navigate));
    }

    const approveHandler = async e => {
        e.preventDefault();
        const data = {
            // newStatus:
        } ;
       dispatch(_approveProduct(editItem?._id , data , toast , navigate));
    }
    const handleStatusChange = e => {
        if(window.confirm('Are you sure? You want to update Product status?')){
            setProductStatusValue(e.target.value);
            const data = { newStatus : e.target.value }

            dispatch(_approveProduct(editItem?._id , data , toast , navigate));

        }
    }

    const removeImage = item => {
        setImages(prev => prev.filter(img => img !== item ))
    }

    const deleteImg = async (item) => {
        try {
            await Axios.post(`/products/delete-image/${editItem?._id}` , {
                image : item 
            } , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            dispatch(removeEditItemImg(item))
            toast.success('Image deleted successfully.')
        } catch (err) {
            console.log('Delete Image Error' , err);
            toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
        }

    }
    
    return (
        <div className="sm:p-6 py-4 px-2">
            <div className="flex items-center justify-between">
                <Heading title='Add New Product' />
                <select 
                        className='border rounded-md outline-none py-2 px-2'
                        onChange={handleStatusChange}
                        value={productStatusValue}
                        >
                            <option value="">Status</option>
                            <option value="0">Pending</option>
                            <option value="1">Approved</option>
                            <option value="2">Decline</option>
                        </select>
                <BackBtn />
            </div>
            <div>
                <form
                onSubmit={submitHandler} 
                className="flex flex-col gap-6"
                >
                    <div className='shadow-bg sm:py-6 py-4 sm:px-6 px-3 mt-6 flex flex-col gap-6'>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Name (EN)</label>
                            <input 
                            type="text" 
                            placeholder='New Product'
                            className='textField'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Short Description (EN)</label>
                            <textarea
                            type="text" 
                            placeholder='Product description...'
                            className='textField resize-none'
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            rows={8}
                            />
                        </div>
                        
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-8 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Price</label>
                            <input 
                            type="number" 
                            placeholder='Ex : 100'
                            className='textField'
                            value={price}
                            onChange={e => setPrice(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Unit</label>
                            <SelectBox 
                            options={[
                                {value : "kg" , label : "Kg"},
                                {value : "gm" , label : "Gm"},
                                {value : "ltr" , label : "ltr"},
                                {value : "pc" , label : "Pc"},
                            ]} 
                            setValue={setUnit}
                            value={unit}
                            />
                        </div>
                    </div>
                 
                    <div className="flex sm:items-center sm:gap-8 gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Discount</label>
                            <input 
                            type="number" 
                            placeholder='Ex : 7'
                            className='textField'
                            value={discount}
                            onChange={e => setDiscount(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Discount Type</label>
                            <SelectBox 
                            options={[
                                {value : "amount" , label : "Amount"},
                                {value : "percent" , label : "Percent"},
                            ]} 
                            setValue={setDiscountType}
                            />
                        </div>
                        
                    </div>
                    <div className="flex sm:items-center sm:gap-8 gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Supplier * </label>
                            {
                                supplierLoading
                                ? 
                                    <ClipLoader size={15} />
                                : 
                                <SelectBox 
                                options={suppliers?.map(item => (
                                    {value : item?._id  , label : item?.firstName + item?.lastName }
                                ))} 
                                setValue={setSupplier}
                                value={supplier}
                                />
                            }
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Stock</label>
                            <input 
                            type="number" 
                            placeholder='Ex : 15'
                            className='textField'
                            value={stock}
                            onChange={e => setStock(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-8 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Category * </label>
                            <SelectBox 
                            options={categories?.map(item => (
                                {value : item?._id  , label : item?.name }
                            ))} 
                            setValue={setCategory}
                            value={category}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Sub Category</label>
                            {
                                loading 
                                ? 
                                    <ClipLoader size={15} />
                                : 
                                <SelectBox 
                                options={subCategories?.map(item => (
                                    { value : item?._id , label : item?.name }
                                ))} 
                                setValue={setSubCategory}
                                value={subCategory}
                                />
                               
                            }
                        </div>
                    </div>
                    {/* MULTI SELECT */}
                    <div className="border rounded-md p-4 pb-6 mt-3">
                         <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Attributes </label>
                            <MultiSelectBox 
                            options={attributes?.map(item => (
                                { value : '' , label : item?.name , name : item?.name }
                            ))} 
                            setselectedAttributes={setselectedAttributes}
                            selectedAttributes={selectedAttributes}
                            />
                        </div>
                        {
                            selectedAttributes?.length > 0 
                            ? 
                            <div className="mt-8 flex flex-col gap-4">
                                {
                                    selectedAttributes?.map((attr , i) => (
                                        <div className="flex items-center gap-8" key={i}>
                                            <div className="flex-[0.3]">
                                                <input 
                                                className="textField w-full"
                                                value={attr?.name} 
                                                readOnly
                                                />
                                            </div>
                                            <div className="flex-[0.7]">
                                                <input 
                                                type="text" 
                                                placeholder='Enter choice values'
                                                className='textField w-full'
                                                name={attr.name}
                                                onChange={handleAttrChange}
                                                value={attr.value}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            : ''
                        }
                    </div>

                    <div>
                        <label className='text-[15px]'>Product Image* </label>
                        <input 
                        type="file"
                        onChange={handleFileChange} 
                        ref={prodImgRef} 
                        hidden 
                        />
                        <div className="flex items-center gap-4 flex-wrap mt-2">
                            {
                                editItem?.images?.length > 0 && editItem?.images?.map((item , i) => (
                                    <div
                                    key={i} 
                                    className="w-[200px] h-[150px] rounded-md relative"
                                    >
                                        <img 
                                        src={`${stroageBaseURL}/products/${item}`} 
                                        alt="Product" 
                                        className="w-full h-full rounded-md object-cover"
                                        />
                                        <div 
                                        className="absolute top-1 right-1 text-white hover:text-red-500 rounded-sm px-1 cursor-pointer"
                                        onClick={() => deleteImg(item)}
                                        >
                                            <i className="uil uil-trash"></i>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="flex items-center gap-4 flex-wrap mt-2">
                            {
                                images?.length > 0 && images?.map((item , i) => (
                                    <div
                                    key={i} 
                                    className="w-[200px] h-[150px] rounded-md relative"
                                    >
                                        <img 
                                        src={item} 
                                        alt="Product" 
                                        className="w-full h-full rounded-md object-cover"
                                        />
                                        <div 
                                        className="absolute top-1 right-1 text-white hover:text-red-500 rounded-sm px-1 cursor-pointer"
                                        onClick={() => removeImage(item)}
                                        >
                                            <i className="uil uil-trash"></i>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="border-2 border-dashed p-1 rounded-md mt-2 cursor-pointer w-[200px] h-[150px] flex items-center justify-center text-2xl font-bold text-gray-500"
                            onClick={() => prodImgRef?.current?.click() }
                            >
                                +
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button type="submit" className="btn-primary">
                            {
                                updateLoading
                                ? 
                                    <ClipLoader size={20} color='$fff' />
                                : 
                                    'Submit'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditProduct;