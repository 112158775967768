import React from 'react'

const UserDetailsCard = ({ user , address }) => {
    

    return (
        <div className='shadow-bg rounded-md'>
            <div className='text-lg font-semibold border-b py-3'>
                <div className='px-4 text-gray-500'>Customer</div>
            </div>
            <div className='border-b'>
                <div className='flex items-center gap-3 px-4 py-3'>
                    <div className='bg-darkSlate rounded-full w-[40px] h-[40px] flex items-center justify-center text-grayText text-xl cursor-pointer '>
                        <i className="uil uil-user -translate-y-0.5"></i>
                    </div>
                    <p className='text-gray-500'>
                        {user?.firstName + " " + user?.lastName}
                    </p>
                </div>
            </div>
            <div className='border-b'>
                <div className='flex items-center gap-3 px-4 py-3'>
                    <div className='bg-darkSlate rounded-full w-[40px] h-[40px] flex items-center justify-center text-grayText text-xl cursor-pointer '>
                        <i className="uil uil-shopping-cart -translate-y-0.5"></i>
                    </div>
                    <p className='text-gray-500'>0 orders</p>
                </div>
            </div>
            <div className='border-b'>
                <div className='py-3 px-4'>
                    <h6 className='text-base font-semibold'>Contact Info</h6>
                    <div className='flex flex-col gap-2 mt-2 text-gray-500'>
                        <div className='flex items-center gap-3'>
                            <p>@</p>
                            <p>{user?.email}</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p>
                                <i className="uil uil-phone"></i>
                            </p>
                            <p>{user?.phone || 'Not defined'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-4 px-4'>
                <h6 className='text-base font-semibold'>Address</h6>
                {
                    address && 
                    <div className='flex flex-col gap-3 mt-2 text-gray-500'>
                        <div className='flex items-center gap-3'>
                            <p className='font-semibold'>
                                City:
                            </p>
                            <p>{address?.city || 'Not defined'}</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-semibold'>
                                Phone No:
                            </p>
                            <p>{address?.phoneNo || 'Not defined'}</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-semibold'>
                                House No:
                            </p>
                            <p>{address?.houseNo || 'Not defined'}</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-semibold'>
                                Street No:
                            </p>
                            <p>{address?.streetNo || 'Not defined'}</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-semibold'>
                                Block:
                            </p>
                            <p>{address?.block || 'Not defined'}</p>
                        </div>
                        <div className='flex items-center gap-3'>
                            <p className='font-semibold'>
                                Nearest Landmark:
                            </p>
                            <p>{address?.nearestLandmark || 'Not defined'}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default UserDetailsCard