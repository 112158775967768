import { createSlice } from "@reduxjs/toolkit";


const supplierSlice = createSlice({
    name : 'supplier' ,
    initialState : {
        suppliers : [] ,
        supplierReport : null , 
        loading : false ,
        createLoading : false ,
        updateLoading : false ,
        deleteLoading : false ,
        reportLoading : false ,
        currentPage : 1 ,
        pages : 1 
    } ,
    reducers : {
        addSupplier (state , action) {
            state.suppliers.unshift(action.payload);
        } ,
        setSupplierReport (state , action) {
            state.supplierReport = action.payload;
        } ,
        removeSupplier (state , action) {
            state.suppliers = state.suppliers.filter(item => item._id !== action.payload);
        } ,
        setSuppliers (state , action) {
            state.suppliers = action.payload;
        } ,
        updateSupplier (state , action) {
            const index = state.suppliers.findIndex(item => item._id === action.payload._id);
            state.suppliers[index] = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setReportLoading (state , action) {
            state.reportLoading = action.payload;
        } ,
        setUpdateLoading (state , action) {
            state.updateLoading = action.payload;
        } ,
        setDeleteLoading (state , action) {
            state.deleteLoading = action.payload;
        } ,
        setCreateLoading (state , action) {
            state.createLoading = action.payload;
        } , 
        setCurrentPage (state , action){
            state.currentPage = action.payload;
        } , 
        setPages (state , action){
            state.pages = action.payload;
        }
    }
});


export const { 
    addSupplier , updateSupplier , removeSupplier , setDeleteLoading , setLoading , setUpdateLoading , setCreateLoading , setPages , setCurrentPage  , setSuppliers , setSupplierReport , setReportLoading
} = supplierSlice.actions;

export default supplierSlice.reducer;

