import React from 'react'

const ToggleBtn = ({ status , setStatus }) => {
    const handleChange = e => {
        if(e.target.checked) return setStatus(true);
        setStatus(false);
    }

    return (
        <div className='flex items-center gap-3'>
            <p className='font-semibold text-dark '>Check Status</p>
            <label className="relative inline-flex items-center mr-5 cursor-pointer">
            <input 
            type="checkbox" 
            value="" 
            className="sr-only peer" 
            onChange={handleChange}
            checked={status}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-600"></div>
            </label>
        </div>
    )
}

export default ToggleBtn