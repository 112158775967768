import AddSubCatPopup from 'components/categories/AddSubCatPopup';
import SubCategoryTable from 'components/categories/SubCategoryTable';
import CatelogTable from 'components/catelog/CatelogTable';
import useToggle from 'hooks/useToggle';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { getCatelogs } from 'redux/actions/catelogActions';

const Catelog = () => {
    const [showAddSubCatPopup , toggleAddSubCatPopup] = useToggle(false);
    const dispatch = useDispatch();
    const { currentPage , subCategories , loading } = useSelector(state => state.catelog);
    const [editItem , setEditItem] = useState(null);

    useEffect(() => {
        dispatch(getCatelogs(toast));
    }, [currentPage])

    return (
        <div className='sm:p-6 py-4 px-2 min-h-screen h-full'>
            <div className='flex items-center justify-between gap-6'>
                <h1 className='font-semibold sm:text-2xl text-xl'>Catelogs</h1>
                <Link to='/add-catelog'>
                        <div className='btn-primary p-sm flex gap-2 w-fit'>
                            <i className="uil uil-plus-circle text-lg"></i>
                            <span>Add New Catelog</span>
                        </div>
                    </Link>
            </div>
            {
                loading 
                ? 
                    <div className='w-full h-[250px] flex items-center justify-center border rounded-md mt-8'>
                        <ClipLoader size={20} color='#000' />
                    </div>
                : 
                subCategories?.length > 0 
                ? 
                    <div>
                        <CatelogTable 
                        setEditItem={setEditItem}
                        toggleAddSubCatPopup={toggleAddSubCatPopup}
                        />
                    </div>
                : 
                    <div className='w-full h-[250px] flex items-center justify-center text-2xl font-semibold border rounded-md mt-8'>
                        No item found.
                    </div>
            }
        </div>
    )
}

export default Catelog;