import React , { forwardRef } from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment';

const ProductReportTable = forwardRef(({} , ref) => {
    const { productReport : { orders } } = useSelector(state => state.product);

    return (
        <div 
        className="overflow-x-auto rounded-lg report"
        ref={ref}
        >
            <table className="w-full table-auto overflow-x-auto">
                <thead className="bg-lightSlate border-b text-[13px]">
                    <tr>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            #
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            ORDER
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            DATE
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            CUSTOMER
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            QTY
                        </th>
                        <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                            AMOUNT
                        </th>
                    </tr>
                </thead>
                <tbody className='text-[13px]'>
                {
                        orders?.map((item , i) => (
                            <tr className="bg-white border-b transition duration-300 ease-in-out">
                            <td className="px-6 py-4 whitespace-nowrap  font-medium text-gray-900">{i+1}</td>
                            <td className=" text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?.orderId}   
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                            {moment(item?.createdAt).format('DD MMM YYYY')}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {item?.user?.firstName + " " + item?.user?.lastName}
                            </td>
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                {item?.qty}
                            </td>
                            
                            <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
                                PKR {item?.orderAmount}
                            </td>
                    
                            
                        </tr>
                        ))
                }
                
                </tbody>
            </table>
        </div>
    )
})

export default ProductReportTable;