import CouponsTable from 'components/coupons/CouponsTable';
import Search from 'components/global/Search';
import React from 'react'
import { Link } from 'react-router-dom';

const Coupons = () => {
    return (
        <div className='sm:py-6 py-4 sm:px-5 px-2 '>
            <div className='flex items-center justify-between '>
                <h1 className='font-semibold text-2xl'>Coupons</h1>
                <Link to='/coupon/add-new' className='btn-primary p-sm'>
                    <div>
                        <i className="uil uil-plus-circle"></i>
                    </div>
                    <p>Add New Coupon</p>
                </Link>
            </div>
            <div className='shadow-bg mt-6'>
                <div className='sm:p-5 py-4 px-2 flex justify-end'>
                    <Search />
                </div>
                <div>
                    <CouponsTable />
                </div>
            </div>
        </div>
    )
}

export default Coupons;