import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
    name : 'notification' , 
    initialState : {
        notifications : [] ,
        loading : false ,
        deleteLoading : false ,
        updateLoading : false ,
        createLoading : false , 
        pages : 1 ,
        currentPage : 1 ,
    } , 
    reducers : {
        addNotification (state , action) {
            state.notifications.unshift(action.payload)
        } ,
        removeNotification (state , action) {
            state.notifications = state.notifications.filter(item => item._id !== action.payload);
        } ,
        setNotification (state , action) {
            state.notifications = action.payload;
        } ,
     
        updateNotification (state , action) {
            const index = state.notifications.findIndex(item => item._id === action.payload._id);
            state.notifications[index] = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setUpdateLoading (state , action) {
            state.updateLoading = action.payload;
        } ,
        setDeleteLoading (state , action) {
            state.deleteLoading = action.payload;
        } ,
        setCreateLoading (state , action) {
            state.createLoading = action.payload;
        } , 
        setCurrentPage (state , action){
            state.currentPage = action.payload;
        } , 
        setPages (state , action){
            state.pages = action.payload;
        }
    }
});

export const {
    addNotification , removeNotification , setNotification , updateNotification , setLoading , setUpdateLoading , setDeleteLoading , setCreateLoading , setCurrentPage , setPages , setSubCategories 
} = notificationSlice.actions;

export default notificationSlice.reducer;