import Pagination from 'components/global/pagination';
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteSubCategory } from 'redux/actions/sectionActions';
import { setCurrentPage,setEditItem } from 'redux/reducers/sectionReducer';
import useClickOutside from 'utils/clickOutside';
// import { setCurrentPage, setEditItem } from 'redux/reducers/productReducer';

const SectionTable = ({ toggleAddSubCatPopup ,  }) => {
    const dropMenuRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    const { subCategories , pages , currentPage } = useSelector(state => state.section);

    useClickOutside(dropMenuRef , () => setShowDropMenu(false));

    // const editHandler = (item) => {
    //     setEditItem(item);

    //     // toggleAddSubCatPopup(true);
    // }
    const editHandler = item => {
        dispatch(setEditItem(item));
        navigate(`/section/edit/${item?._id}`);
    }

    const deleteHandler = async catId => {
        if(window.confirm('Are you sure? you want to delete this item?')){
            await dispatch(deleteSubCategory(catId , toast));
            setShowDropMenu(false);
        }
    }

    return (
        <div className="overflow-x-auto bg-pure mt-8 mb-6 rounded-lg border shadow-md pb-20">
            <table className="w-full table-auto">
                <thead className="bg-lightSlate border-b">
                    <tr>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                           Title
                        </th>
                        {/* <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Supplier
                        </th> */}
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Products Count
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                            ACTION
                        </th>
                    </tr>
                </thead>
                <tbody>
                   {
                        subCategories?.map((item , i) => (
                            <tr className="bg-white border-b transition duration-300 ease-in-out">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{i+1}</td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?.name}
                            </td>
                            {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?.owner.firstName} {item?.owner.lastName}
                            </td> */}
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <p className='border py-2.5 px-3 flex items-center gap-2 cursor-pointer'>
                
                                    <span>
                                       Totle Products : {item?.productIds.length }
                                    </span>
                                </p>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                    {/* DROP MENU */}
                                    {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                       <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                            onClick={() => editHandler(item)}
                                            >
                                                Edit
                                            </div>
                                       <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                            onClick={() => deleteHandler(item._id)}
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
            <Pagination 
            pageCount={pages}
            currentPage={currentPage}
            setPage={setCurrentPage}
            />
        </div>
    )
}

export default SectionTable;