import React, { useRef, useState } from 'react';
import Img1 from 'assets/images/img1.jpg';
import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCat } from 'redux/actions/categoryActions';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { stroageBaseURL } from 'config/api';
import isBase64 from 'utils/isBase64';


const AddMainCatPopup = ({ toggleAddCatPopup , editItem , setEditItem }) => {
    const dispatch = useDispatch();
    const imgRef = useRef();
    const bannerImgRef = useRef();
    const formRef = useRef(null);
    const [image , setImage] = useState(
        editItem ? `${stroageBaseURL}/categories/${editItem?.image}` : ''
    );
    const [bannerImage , setBannerImage] = useState(
        editItem ? `${stroageBaseURL}/categoryBanners/${editItem?.bannerImage}` : ''
    );
    const [name , setName] = useState(editItem?.name || '');

    const { createLoading , updateLoading } = useSelector(state => state.category);

    useClickOutside(formRef , () =>  {
        toggleAddCatPopup();
        setEditItem(null);
    });

    const handleFileChange = (event , setValue) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setValue(reader.result);
        };
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if(editItem){
            const data = { name };
            if(isBase64(image)){
                data.image = image;
            }
            if(isBase64(bannerImage)){
                data.bannerImage = bannerImage
            }
            console.log('updated data' , data);
            await dispatch(updateCat(editItem?._id , data , toast));
        }else {
            const data = { image , bannerImage , name }
            await dispatch(createCategory(data , toast ));
        }
        setName('');
        toggleAddCatPopup(false);
        setEditItem(null);
    }

    return (
        <div className='fixed top-0 left-0 w-full min-h-screen bg-[#00000063] flex items-center justify-center z-[9999]'
        
        >
            <div className='md:w-[70%] w-[95%]  overflow-auto bg-pure rounded-lg border shadow-lg py-4 px-4'
            ref={formRef}
            >
                <div className='flex items-center justify-between pb-3 border-b'>
                    <div className='flex items-center gap-2 '>
                        <div className='text-lg'>
                            <i className="uil uil-plus-circle"></i>
                        </div>
                        <h3 className='font-semibold'>Add New Category</h3>
                    </div>
                    <div className='text-xl cursor-pointer' onClick={() => {
                        toggleAddCatPopup(false);
                        setEditItem(null);
                    }} >
                        <i className="uil uil-times"></i>
                    </div>
                </div>
                <div className='mt-8 h-[75vh] overflow-auto'> 
                    <form 
                    className='flex flex-col gap-4'
                    onSubmit={submitHandler}
                    >
                        <div className='flex flex-col gap-2'>
                            <label className='text-[15px]' htmlFor="pName" >Name (EN)</label>
                            <input 
                            id='pName'
                            type="text" 
                            placeholder='New Category'
                            className='textField'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className='flex md:flex-row flex-col gap-4 text-[15px]'>
                            <div className='flex-1 flex flex-col gap-4'>
                                <div className='flex-1 flex flex-col gap-2'>
                                    <label className='text-[15px]'>Image *</label>
                                    <input 
                                    type='file'
                                    ref={imgRef}
                                    onChange={e => handleFileChange(e , setImage)}
                                    hidden 
                                    />
                                    <div className='flex items-center justify-between border sm:px-3 px-2 rounded-md cursor-pointer'
                                    onClick={() => (
                                        imgRef.current.click()
                                    )}
                                    > 
                                        <div>Choose File</div>
                                        <div className='py-3 sm:px-6 px-4 border-l'>Browse</div>
                                    </div>
                                </div>
                                <div className='flex-1 '>
                                    <img 
                                    src={image ? image : Img1} 
                                    alt="Category file" 
                                    className='rounded-lg w-full h-[200px] object-cover'
                                    />
                                </div>
                            </div>
                            <div className='flex-1 flex flex-col gap-4'>
                                <div className='flex-1 flex flex-col gap-2'>
                                    <label className='text-[15px]'>Banner Image *</label>
                                    <input 
                                    type='file'
                                    ref={bannerImgRef}
                                    hidden
                                    onChange={ e => 
                                        handleFileChange(e , setBannerImage)
                                    }
                                    />
                                    <div className='flex items-center justify-between border sm:px-3 px-2 rounded-md cursor-pointer'
                                        onClick={() => (
                                            bannerImgRef.current.click()
                                        )}
                                    > 
                                        <div>Choose File</div>
                                        <div className='py-3 sm:px-6 px-4 border-l'>Browse</div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <img 
                                    src={bannerImage ? bannerImage : Img1} 
                                    alt="Category Banner" 
                                    className='rounded-lg w-full h-[200px] object-cover'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-4' type='submit'>
                            <button className="btn-primary">
                                {
                                    createLoading || updateLoading
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    :
                                        'Submit'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddMainCatPopup;