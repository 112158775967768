import Axios from 'config/api';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const SocialMedia = () => {
    const inputRef = useRef(null);
    const [name , setName] = useState('');
    const [url , setUrl] = useState('');
    const [socialMedias , setSocialmedias] = useState([]);
    const [loading , setLoading] = useState(false);
    const [createLoading , setCreateLoading] = useState(false);
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        const fetchSocialMediaLinks = async () => {
            try {
                setLoading(true);
                const { data : { data : { docs } } } = await Axios('/social-media');
                setSocialmedias(docs);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
            }
        }
        fetchSocialMediaLinks();
    }, []);

    const editHandler = item => {
        setName(item?.name);
        setUrl(item?.url);
        window.scroll(0,0)
        inputRef?.current.focus();
    }

    const submitHandler = async e => {
        e.preventDefault();
        const socialData = { name : name.toLocaleLowerCase() , url };
        try {
            setCreateLoading(true);
            const { data : { data : { doc } } } = await Axios.post('/social-media' , socialData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            const index = socialMedias.findIndex(s => s._id === doc._id);
            if(index !== -1){
                setSocialmedias(prev => {
                    prev[index] = doc;
                    return prev;
                })
            }else {
                setSocialmedias(prev => [doc , ...prev]);
            }
            setName('');
            setUrl('');
            setCreateLoading(false);
            toast.success('Saved Successfully.')
        } catch (err) {
            setCreateLoading(false);
            toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
        }
    }

    return (
        <section className='px-5 py-6'>
            {/* socail media form */}
            <div className='shadow-bg rounded-lg border '>
                <div className='px-4 p-3 font-semibold text-lg text-gray-600 border-b'>
                    <p>Social media form</p>
                </div>
                {/* socail media form */}
                <form className='w-full px-5 py-5' onSubmit={submitHandler}>
                    <div className='flex flex-col gap-2'>
                        <label className='text-gray-600' htmlFor="name">
                            Name
                        </label>
                        <select 
                        className='textField'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        >
                            <option value="">--Select--</option>
                            <option value="instagram">Instagram</option>
                            <option value="facebook">Facebook</option>
                            <option value="twitter">Twitter</option>
                            <option value="linkedin">Linkedln</option>
                            <option value="pintrest">Pintrest</option>
                            <option value="youtube">Youtube</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-2 my-3 pb-3'>
                        <label htmlFor="link" className='text-gray-700'>
                            Socail media link
                        </label>
                        <input 
                        type="text"
                        placeholder='Enter Social Media Link' className='textField' 
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        ref={inputRef}
                        />
                    </div>
                    <div className='mt-2' type='submit'>
                        <button 
                        className='btn-primary disabled:cursor-not-allowed'
                        disabled={createLoading || loading } 
                        >
                            {
                                createLoading
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Save'
                            }
                        </button>
                    </div>
                </form>
            </div>
            {/* social media table */}
            <div className='shadow-bg rounded-lg border my-6' >
                <div className='px-4 p-3 text-dark text-lg border-b font-semibold'>
                    <p>Social media table</p>
                </div>
                {/* table */}
                {
                    loading 
                    ? 
                        <div className='flex items-center justify-center h-[150px] '>
                            <ClipLoader size={20} />
                        </div>
                    : 
                    socialMedias?.length > 0 
                    ? 
                        <div className='pb-12 overflow-x-auto'>
                            <table className="w-full table-auto overflow-x-auto">
                                <thead className="bg-lightSlate border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            #
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            NAME
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            LINK
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            ACTION
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    socialMedias?.map((item , i) => (
                                        <tr className="bg-white border-b transition duration-300 ease-in-out">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {i+1}
                                        </td>
                                        <td className="text-sm text-primary px-6 py-4 whitespace-nowrap font-normal">
                                            {item?.name}
                                        </td>
                                        <td className="text-sm text-primary px-6 py-4 whitespace-nowrap font-medium">
                                           {item?.url}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                            <div>
                                                <button 
                                                className="btn-primary p-sm"
                                                onClick={() => editHandler(item)}
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        </td>
                                        
                                        </tr>
                                    ))
                                }
                                    
                                </tbody>
                            </table> 
                        </div>
                    : 
                        <div className='flex items-center justify-center h-[150px] '>
                            No item found.
                        </div>   
                }
            </div>
        </section>
    )
}

export default SocialMedia
