import DatePicker from "react-datepicker";


const DatePicker_ = ({ value , setValue , ...props }) => {

    return (
        <DatePicker 
        selected={value} 
        onChange={(date) => setValue(date)} 
        {...props}
        />
    );
}

export default DatePicker_ ;