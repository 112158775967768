import Axios from "config/api";
import { addAttribute , removeAttribute , setLoading , setAttributes , replaceAttribute } from "redux/reducers/attributeReducer";


export const createAttribute = (data , setName , toast) => async(dispatch , getState) => {
    dispatch(setLoading(true))
    try {
        const { token } = getState().auth.user;

        const { data : { data : { doc } } } = await Axios.post('/attributes' , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(addAttribute(doc));
        setName('');
        dispatch(setLoading(false));
        toast.success('Attribute created Successfully.');
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Create attribute error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const deleteAttribute = (id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`/attributes/${id}` , {
            headers : { Authorization : `Bearer ${token}` }
        });
        dispatch(removeAttribute(id));
        toast.success('Attribute removed successfully.');
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('delete Attribute error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getAttributes = (toast) => async(dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios(`/attributes` , {
            headers : { Authorization : `Bearer ${token}` }
        });
        dispatch(setLoading(false));
        dispatch(setAttributes(docs));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('get Attributes error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const updateAttribute = (id , data , toast , navigate) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`/attributes/${id}` , data , {
            headers : { Authorization : `Bearer ${token}` }
        });
        dispatch(replaceAttribute(doc));
        toast.success('Updated successfully.');
        navigate('/attribute')
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Update Attribute error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getTotalAttributes = (toast) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { docs } } } = await Axios(`/attributes/all`);
        dispatch(setAttributes(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Total attributes error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}