import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Loader from 'components/global/Loader'
import Search from 'components/global/Search'
import SupplierReviewsTable from 'components/suppliers/SupplierReviewsTable'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSupplierReviews } from 'redux/actions/supplierReviewsActions'

const SupplierReviews = () => {
    const dispatch = useDispatch();

    const { currentPage , reviews , loading } = useSelector(state => state.supplierReview);

    useEffect(() => {
        dispatch(getSupplierReviews(toast))
    }, [currentPage])

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center gap-2'>
                <Heading title='Review List' icon={false} />
                <span className='text-primary font-semibold text-2xl'>5</span>
            </div>
            <div className='shadow-bg  mt-6'>
                {
                    loading
                    ? 
                        <Loader />
                    : 
                    reviews?.length > 0 
                    ? 
                        <div>
                            <SupplierReviewsTable />
                        </div>
                    :
                        <ItemNotFound />
                }
            </div>
        </div>
    )
}

export default SupplierReviews