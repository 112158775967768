import { createSlice } from "@reduxjs/toolkit";


const supplierReviewSlice = createSlice({
    name : 'supplierReview' ,
    initialState : {
        reviews : [] ,
        loading : false ,
        currentPage : 1 ,
        pages : 1 ,
        docsCount : 1
    } ,
    reducers : {
        setSupplierReviews (state , action) {
            state.reviews = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } , 
        setCurrentPage (state , action){
            state.currentPage = action.payload;
        } , 
        setPages (state , action){
            state.pages = action.payload;
        } ,
    }
});

export const { 
    setLoading , setSupplierReviews , setCurrentPage , setPages , setDocsCount
} = supplierReviewSlice.actions;

export default supplierReviewSlice.reducer;