import BackBtn from "components/global/BackBtn";
import DatePicker_ from "components/global/Datepicker";
import SelectBox from "components/global/SelectBox";
import { useState } from "react";


const AddNewCoupon = () => {
    const [startDate , setStartDate] = useState(new Date());
    const [endtDate , setEndDate] = useState(new Date());

    return (
        <div className="sm:p-6 py-4 px-2">
            <div className="flex items-center justify-between">
                <h1 className='main-heading'>
                    <i className="uil uil-plus-circle"></i>
                    <span>Add New Coupon</span>
                </h1>
                <div>
                    <BackBtn />
                </div>
            </div>
            <div>
                <form className="flex flex-col gap-6">
                    <div className='shadow-bg sm:py-6 py-4 sm:px-6 px-2 mt-6 flex flex-col gap-6'>
                        <div className="flex sm:items-center sm:flex-row flex-col gap-4">
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Title</label>
                                <input 
                                type="text" 
                                placeholder='New Coupon'
                                className='textField'
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Coupon Type</label>
                                <SelectBox
                                options={[
                                    {value : "default" , label : "Default"},
                                    {value : "firstOrder" , label : "First Order"}
                                ]} 
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Limit for same user</label>
                                <input 
                                type="text" 
                                placeholder='Ex : 10'
                                className='textField'
                                />
                            </div>
                        </div>
                        <div className="flex sm:items-center sm:flex-row flex-col gap-4">
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Code</label>
                                <input 
                                type="text" 
                                placeholder='foj5yek3'
                                className='textField'
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Start Date</label>
                                <DatePicker_ 
                                value={startDate} 
                                setValue={setStartDate} 
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>End Date</label>
                                <DatePicker_ 
                                value={endtDate} 
                                setValue={setEndDate} 
                                />
                            </div>
                        </div>
                        <div className="flex sm:items-center sm:flex-row flex-col gap-4">
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Min Purchase</label>
                                <input 
                                type="number" 
                                placeholder='0'
                                className='textField'
                                min={0}
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Max Purchase</label>
                                <input 
                                type="number" 
                                placeholder='0'
                                className='textField'
                                min={0}
                                />
                            </div>
                            <div className='flex-1 flex flex-col gap-2'>
                                <label className='text-[15px]'>Discount</label>
                                <input 
                                type="text" 
                                placeholder='Ex : $10 or 10%'
                                className='textField'
                                min={0}
                                />
                            </div>
                            
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Discount Type</label>
                            <SelectBox
                            options={[
                                {value : "percent" , label : "Percent"},
                                {value : "amount" , label : "Amount"}
                            ]} 
                            />
                        </div>
                        <div>
                            <button className="btn-primary">SUBMIT</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddNewCoupon;