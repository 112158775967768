import AddSubCatPopup from 'components/categories/AddSubCatPopup';
import SubCategoryTable from 'components/categories/SubCategoryTable';
import useToggle from 'hooks/useToggle';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { getSubCategories } from 'redux/actions/subCategoryActions';

const SubCategory = () => {
    const [showAddSubCatPopup , toggleAddSubCatPopup] = useToggle(false);
    const dispatch = useDispatch();
    const { currentPage , subCategories , loading } = useSelector(state => state.subCategory);
    const [editItem , setEditItem] = useState(null);

    useEffect(() => {
        dispatch(getSubCategories(toast));
    }, [currentPage])

    return (
        <div className='sm:p-6 py-4 px-2 min-h-screen h-full'>
            <div className='flex items-center justify-between gap-6'>
                <h1 className='font-semibold sm:text-2xl text-xl'>Sub Categories</h1>
                <div className='btn-primary p-sm'>
                    <div>
                        <i className="uil uil-plus-circle"></i>
                    </div>
                    <h1 onClick={() => toggleAddSubCatPopup(true)}>
                        Add Sub Category
                    </h1>
                </div>
            </div>
            {
                loading 
                ? 
                    <div className='w-full h-[250px] flex items-center justify-center border rounded-md mt-8'>
                        <ClipLoader size={20} color='#000' />
                    </div>
                : 
                subCategories?.length > 0 
                ? 
                    <div>
                        <SubCategoryTable 
                        setEditItem={setEditItem}
                        toggleAddSubCatPopup={toggleAddSubCatPopup}
                        />
                    </div>
                : 
                    <div className='w-full h-[250px] flex items-center justify-center text-2xl font-semibold border rounded-md mt-8'>
                        No item found.
                    </div>
            }

            {
                showAddSubCatPopup && 
                <div>
                    <AddSubCatPopup 
                    toggleAddSubCatPopup={toggleAddSubCatPopup}
                    editItem={editItem}
                    setEditItem={setEditItem}
                    />
                </div>
            }
        </div>
    )
}

export default SubCategory;