
import React from 'react'

const SettingsSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1 9.22104C18.29 9.22104 17.55 7.94104 18.45 6.37104C18.97 5.46104 18.66 4.30104 17.75 3.78104L16.02 2.79104C15.23 2.32104 14.21 2.60104 13.74 3.39104L13.63 3.58104C12.73 5.15104 11.25 5.15104 10.34 3.58104L10.23 3.39104C10.1222 3.20202 9.97789 3.03632 9.80545 2.9036C9.63302 2.77088 9.43591 2.67377 9.2256 2.61794C9.01529 2.5621 8.79597 2.54865 8.58041 2.57837C8.36485 2.60809 8.15736 2.68038 7.97 2.79104L6.24 3.78104C5.33 4.30104 5.02 5.47104 5.54 6.38104C6.45 7.94104 5.71 9.22104 3.9 9.22104C2.86 9.22104 2 10.071 2 11.121V12.881C2 13.921 2.85 14.781 3.9 14.781C5.71 14.781 6.45 16.061 5.54 17.631C5.02 18.541 5.33 19.701 6.24 20.221L7.97 21.211C8.76 21.681 9.78 21.401 10.25 20.611L10.36 20.421C11.26 18.851 12.74 18.851 13.65 20.421L13.76 20.611C14.23 21.401 15.25 21.681 16.04 21.211L17.77 20.221C18.68 19.701 18.99 18.531 18.47 17.631C17.56 16.061 18.3 14.781 20.11 14.781C21.15 14.781 22.01 13.931 22.01 12.881V11.121C22.0048 10.617 21.8015 10.1353 21.4442 9.77981C21.0868 9.42433 20.604 9.22363 20.1 9.22104ZM12 15.251C10.21 15.251 8.75 13.791 8.75 12.001C8.75 10.211 10.21 8.75104 12 8.75104C13.79 8.75104 15.25 10.211 15.25 12.001C15.25 13.791 13.79 15.251 12 15.251Z" fill="black" />
        </svg>

    )
}

export default SettingsSvg
