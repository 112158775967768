import Loader from 'components/global/Loader';
import RichEditor from 'components/global/RichEditor';
import ToggleBtn from 'components/global/ToggleBtn';
import Axios from 'config/api';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

const CancellationPolicy = () => {
    const [content, setContent] = useState('');
    const [status , setStatus] = useState(false);
    const { user } = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);
    const [updateLoading , setUpdateLoading] = useState(false);
    const [policy , setPolicy] = useState('');

    useEffect(() => {
        const fetchCancellationPolicy = async () => {
            try {
                setLoading(true);
                const { data : { data : { policy : doc } } } = await Axios('/cancellation-policy/admin');
                setPolicy(doc)
                setContent(doc?.content);
                setStatus(doc?.isActive)
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
            }
        }
        fetchCancellationPolicy();
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        const data = { content , isActive : status };
        try {
            setUpdateLoading(true);
            const { data : { data : { policy : doc } } } = await Axios.post('/cancellation-policy' , data , {
                headers : {
                    Authorization : `Bearer ${user.token}`
                }
            } );
            setPolicy(doc);
            setContent(doc?.content);
            setStatus(doc?.isActive)
            toast.success('Saved successfully.')
            setUpdateLoading(false);
        } catch (err) {
            setUpdateLoading(false);
            toast.error(err?.resonse?.data?.message || err?.message || 'Something went wrong.')
        }
    }

    return (
        <section className='px-5 py-6'>
            <div className='pb-6 text-2xl font-semibold' >
                <h1>Cancellation Policy</h1>
            </div>
            {
                loading 
                ? 
                    <Loader />
                : 
                <form onSubmit={submitHandler}>
                    <div className='shadow-bg rounded-lg border px-5 py-8 flex flex-col gap-4'>
                        <div className='mb-2'>
                            <ToggleBtn 
                            status={status}
                            setStatus={setStatus}
                            />
                        </div>
                        <RichEditor
                            content={content}
                            setContent={setContent}
                        />
                        <div className='mt-8'>
                            <button className='btn-primary' type='submit'>
                            {
                                updateLoading
                                ?
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Save'
                            }
                            </button>
                        </div>
                    </div>
                </form>
            }
        </section>
    )
}

export default CancellationPolicy
