import Axios from "config/api";
import { 
    addSupplier , removeSupplier, setSuppliers, setCreateLoading, setDeleteLoading , updateSupplier , setLoading, setUpdateLoading, setPages, setCurrentPage, setReportLoading, setSupplierReport
} from "redux/reducers/supplierReducer";

const endPoint = '/admin/suppliers'

export const createSupplier = (data , toast , navigate) => async (dispatch , getState) => {
    try {
        dispatch(setCreateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.post(`${endPoint}` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(addSupplier(doc));
        dispatch(setCreateLoading(false));
        navigate('/supplier/list');
        toast.success('Supplier added successfully.');
    } catch (err) {
        dispatch(setCreateLoading(false));
        console.log('Create Supplier error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const _updateSupplier = (id , data , toast , navigate) => async (dispatch , getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`${endPoint}/${id}` , data  , 
        { headers : { Authorization : `Bearer ${token}`} });
        dispatch(updateSupplier(doc));
        dispatch(setUpdateLoading(false));
        navigate('/supplier/list');
        toast.success('Updated Successfully.');
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update Supplier error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const getSuppliers = (toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs , pages , page } } } = await Axios(`${endPoint}?page=${getState().supplier.currentPage}` , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setSuppliers(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(page));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Suppliers error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const deleteSupplier = (id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setDeleteLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`${endPoint}/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(removeSupplier(id));
        dispatch(setDeleteLoading(false));
        toast.success('Deleted Successfully.');
    } catch (err) {
        dispatch(setDeleteLoading(false));
        console.log('Delete Supplier error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const getSupplierReport = (data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setReportLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { report } } } = await Axios(`${endPoint}/get/report` , data , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(setSupplierReport(report));
        dispatch(setReportLoading(false));
    } catch (err) {
        dispatch(setReportLoading(false));
        console.log('get Supplier Report error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}