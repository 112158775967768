import AddMainCatPopup from 'components/categories/AddMainCatPopup';
import MainCategoryTable from 'components/categories/MainCategoryTable';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import useToggle from 'hooks/useToggle';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCategories } from 'redux/actions/categoryActions';
import { setCurrentPage } from 'redux/reducers/categoryReducer';

const Category = () => {
    const [showAddCatPopup , toggleAddCatPopup] = useToggle(false);
    const { loading , categories , pages , currentPage } = useSelector(state => state.category);
    const [editItem , setEditItem] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategories(toast));
    } , [currentPage]);
    

    return (
        <div className='sm:p-6 py-4 px-2 min-h-screen h-full'>
            <div className='flex sm:items-center sm:flex-row flex-col-reverse justify-between gap-6'>
                <h1 className='font-semibold sm:text-2xl text-xl'>Main Categories</h1>
                <div className='btn-primary p-sm w-fit'>
                    <div>
                        <i className="uil uil-plus-circle"></i>
                    </div>
                    <h1 onClick={() => toggleAddCatPopup(true)}>Add New Category</h1>
                </div>
            </div>
            {
                loading 
                ? 
                    <Loader />
                : 
                    categories?.length > 0
                    ? 
                        <div className="overflow-x-auto bg-pure mt-8 mb-6 rounded-lg border shadow-md pb-20">
                            <MainCategoryTable 
                            toggleAddCatPopup={toggleAddCatPopup}
                            showAddCatPopup={showAddCatPopup}
                            setEditItem={setEditItem}
                            />
                            {/* <div>
                                <Pagination 
                                pageCount={pages} 
                                currentPage={currentPage}
                                setPage={setCurrentPage}
                                />
                            </div> */}
                        </div>
                    : 
                    <div className='w-full h-[250px] flex items-center justify-center text-2xl font-semibold border rounded-md mt-8'>
                        No item found.
                    </div>
            }

            {/* ADD CATEGORY POPUP */}
            {
                showAddCatPopup && 
                <div>
                    <AddMainCatPopup 
                    toggleAddCatPopup={toggleAddCatPopup}
                    editItem={editItem}
                    setEditItem={setEditItem}
                    />
                </div>
            }
        </div>
    )
}

export default Category;