import { createSlice } from '@reduxjs/toolkit';


const customerSlice = createSlice({
    name : 'customer' ,
    initialState : {
        customers : [] ,
        customerDetails : {} ,
        loading : false ,
        updateLoading : false ,
        orderLoading : false ,
        deleteLoading : false ,
        currentPage : 1 ,
        pages : 1 
    } ,
    reducers : {
        setCustomers (state , action) {
            state.customers = action.payload;
        } ,
        setCustomerDetails (state , action) {
            state.customerDetails = action.payload;
        } ,
        setLoading (state , action) {
            state.loading = action.payload;
        } , 
        setOrderLoading (state , action) {
            state.orderLoading = action.payload;
        } , 
        setCurrentPage (state , action){
            state.currentPage = action.payload;
        } , 
        setPages (state , action){
            state.pages = action.payload;
        } ,
        
    }
});

export const {
     setCustomers , setCustomerDetails , setLoading , setCurrentPage , setPages , setOrderLoading
} = customerSlice.actions;

export default customerSlice.reducer;