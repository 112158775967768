import React from 'react'

const DownArrow = () => {
    return (
        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.71978 0.939941L6.06645 5.28661C6.57979 5.79994 7.41978 5.79994 7.93312 5.28661L12.2798 0.939941" stroke="black" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default DownArrow
