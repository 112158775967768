import Axios from "config/api";
import { 
    setCustomerDetails , setCustomers , setLoading, setPages, setCurrentPage , setOrderLoading
} from "redux/reducers/customerReducer";

const endPoint = '/admin/users'

export const getCustomers = (toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs , pages , page } } } = await Axios(`${endPoint}?page=${getState().customer.currentPage}` , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setCustomers(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(page));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Customers error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const getCustomerDetails = (id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { doc } } } = await Axios(`${endPoint}/${id}` , {
            headers : {
                Authorization : `Bearer ${getState().auth.user.token}`
            }
        });
        dispatch(setCustomerDetails(doc));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Customers error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

