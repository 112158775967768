import UserDetailsCard from 'components/customersList/UserDetailsCard';
import BackBtn from 'components/global/BackBtn';
import OrderStatus from 'components/global/OrderStatus';
import PaymentStatus from 'components/global/PaymentStatus';
import { stroageBaseURL } from 'config/api';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getOrderDetails, _updateOrder } from 'redux/actions/orderActions';

const OrderDetails = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { orderId } = useParams();
    const { orderDetails , loading } = useSelector(state => state.order);

    useEffect(() => {
        dispatch(getOrderDetails(orderId , toast))
    } , []);

    const handlePaymentChange = (e) => {
        if(window.confirm('Are you sure? You want to update payment status?')){
            const data = { paymentStatus : e.target.value }
            dispatch(_updateOrder(orderId , data , toast ))
        }
    }

    const handleStatusChange = (e) => {
        if(window.confirm('Are you sure? You want to update order status?')){
            const data = { orderStatus : e.target.value }
            dispatch(_updateOrder(orderId , data , toast ))
        }
    }
    const handleForReturnOrder = (e) => {
        if(window.confirm('Are you sure? You want to return this order after deliver?')){
            const data = { orderStatus : "returned" }
            dispatch(_updateOrder(orderId , data , toast ))
        }
    }

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='pb-6'>
                <BackBtn />
            </div>
            <div className='flex items-center justify-between lg:flex-row flex-col lg:gap-4 gap-6'>
                <div className='flex-[0.7] flex items-center space-x-6'>
                    <h3 className='text-xl font-semibold'>
                        Order #{orderDetails?.customOrderId} 
                    </h3>
                    <div>
                        <PaymentStatus 
                        status={orderDetails?.paymentStatus}
                        />
                    </div>
                    <div className='text-sm  w-[90px]'>
                        <OrderStatus 
                        status={orderDetails?.orderStatus}
                        />
                    </div>
                    <span className='text-gray-500 text-sm'> 17 Nov 2021 03:15 am</span>
                </div>
                <div className='flex-[0.3] flex lg:items-center items-start justify-start gap-8'>
                    <div>
                        <select 
                        className='border rounded-md outline-none py-2 px-2'
                        onChange={handlePaymentChange}
                        >
                            <option value="">Payment</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">unPaid</option>
                        </select>
                    </div>
                    <div>
                        <select 
                        className='border rounded-md outline-none py-2 px-2'
                        onChange={handleStatusChange}
                        >
                            <option value="">Status</option>
                            <option value="canceled">Canceled</option>
                            <option value="confirmed">Confirmed</option>
                        </select>
                    </div>
                </div>
                <div>
            <button 
            className="btn-primary p-sm"
            onClick={() => handleForReturnOrder()}
            >
                Return After Delivery
            </button>
        </div>
            </div>
            <div className='flex gap-6 mt-6 lg:flex-row flex-col '>
                <div className='flex-[0.7]'>
                    {/* order details */}
                    <div className='shadow-lg rounded-lg border '>
                        <div className='p-4 px-5 border-b flex items-center gap-2'>
                            <h1 className='font-semibold text-[1.1rem]'>
                                Order Details 
                            </h1>
                            <div className='flex items-center justify-center w-[20px] h-[20px] text-xs bg-[#E7E8EC] rounded-full'>
                                {orderDetails?.orderItems?.length}
                            </div>
                        </div>
                        {/* details container*/}
                        {
                            orderDetails?.orderItems?.map(item => (
                                <div className='px-5 py-5 flex items-center gap-6 border-b'>
                                    <img 
                                    src={`${stroageBaseURL}/products/${item?.product?.images[0]}`} 
                                    className='w-[100px] object-cover' 
                                    alt={item?.product?.name} 
                                    />
                                    <div className='text-gray-600'>
                                        <h6 className='text-2xl  font-semibold'>{item?.product?.name}</h6>
                                        <h6 className='text-xl  font-semibold pt-3'>Supplier : {item?.product?.owner?.firstName + item?.product?.owner?.lastName}</h6>
                                        <h6 className='text-xl  font-semibold pt-3'>Supplier Email: {item?.product?.owner?.email }</h6>

                                    </div>
                                    <div className='flex items-center justify-between w-full'>
                                        {item?.productSize&&<p className='font-normal'>
                                            Product Size : {item?.productSize}
                                        </p>}
                                       {item?.shoeSizes&& <p className='font-normal'>
                                           Shoe Size : {item?.shoeSizes}
                                        </p>}
                                        <p className='font-normal'>
                                            PKR {item?.product?.price}
                                        </p>
                                        <p>{item?.qty} Pc</p>
                                        <p>PKR {item?.product?.price * item?.qty}</p>
                                    </div>
                                </div>
                            ))
                        }
                        {/* price container */}
                        <div className='p-4 flex flex-col gap-3'>
                           
                            <div className='flex justify-between  '>
                                <h1 className='font-semibold'>Items Price:</h1>
                                <p className='text-gray-500'>
                                    PKR {
                                        orderDetails?.orderItems?.reduce(
                                            (acc, item ) =>  (item?.product?.price * item?.qty) + acc , 0)
                                    }
                                </p>
                            </div>
                            <div className='flex  justify-between '>
                                <h1 className='font-semibold'>Subtotal:</h1>
                                <p className='text-gray-500'>
                                    PKR {
                                        orderDetails?.orderItems?.reduce(
                                            (acc, item ) =>  (item?.product?.price * item?.qty) + acc , 0)
                                    }
                                </p>
                            </div>
                            <div className='flex  justify-between '>
                                <h1 className='font-semibold'>User Profit:</h1>
                                <p className='text-gray-500'>
                                    PKR {
                                        orderDetails?.orderItems?.reduce(
                                            (acc, item ) => item?.userProfit + acc , 0)
                                    }
                                </p>
                            </div>
                            <div className='flex  justify-between border-b pb-4'>
                                <h1 className='font-semibold'>Delivery Fee:</h1>
                                <p className='text-gray-500'>
                                    PKR {orderDetails?.deliveryFee}
                                </p>
                            </div>
                            <div className='flex justify-between py-3 text-2xl font-semibold'>
                                <h1 className=''>Total:</h1>
                                <p className=''>
                                PKR {
                                        // orderDetails?.orderItems?.reduce(
                                        //     (acc, item ) =>  (item?.product?.price * item?.qty) + acc 
                                        // , 0) + orderDetails?.deliveryFee
                                        orderDetails?.orderAmount
                                    } 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-[0.3]'>
                    <UserDetailsCard 
                    user={orderDetails?.user} 
                    address={orderDetails?.shippingAddress}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderDetails;