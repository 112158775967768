import MultiSelectBox from "components/global/MultiSelectBox";
import SelectBox from "components/global/SelectBox";
import { useEffect, useRef, useState } from "react";
import BackBtn from "components/global/BackBtn";
import Heading from "components/global/Heading";
import { useDispatch, useSelector } from "react-redux";
import { getTotalAttributes } from "redux/actions/attributeActions";
import { toast } from "react-toastify";
import { getSubCategories, getTotalCategories } from "redux/actions/categoryActions";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { createProduct } from "redux/actions/productActions";
import { setAttributes } from "redux/reducers/attributeReducer";
import { getSuppliers } from "redux/actions/supplierActions";

const availableSizes = [4 , 5 , 6 , 7 , 8 , 9 , 10 , 11 , 12 , 13 ] ;
const availableProductSizes = ["S" , "M" , "L" , "XL" , "2XL", "32","34","36","38","40","42","44","46","48","50","52","54"] ;

const AddNewProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const prodImgRef = useRef(null);
    const [selectedAttributes , setselectedAttributes] = useState([]);
    const [images , setImages] = useState([]);
    const [attrData , setAttrData] = useState([]);
    const [category , setCategory] = useState(null);
    const [subCategory , setSubCategory] = useState(null);
    const [name , setName] = useState('');
    const [description , setDescription] = useState('');
    const [price , setPrice] = useState('');
    const [unit , setUnit] = useState('');
    const [discount , setDiscount] = useState('');
    const [discountType , setDiscountType] = useState('');
    const [stock , setStock] = useState('');
    const [supplier , setSupplier] = useState('');
    const [shoeSizes , setShoeSizes] = useState([]);
    const [productSizes , setProductSizes] = useState([]);


    // Store States 
    const { attributes } = useSelector(state => state.attributes);
    const { categories , subCategories , loading } = useSelector(state => state.category);
    const { suppliers , loading : supplierLoading } = useSelector(state => state.supplier);
    const { createLoading : createProductLoading } = useSelector(state => state.product);

    const handleAttrChange = (e) => {
        const { name, value } = e.target;
        setselectedAttributes(prev => {
            const index = prev.findIndex(item => item.name === name );
            const updatedObj = {...prev[index] , value};
            return [...prev.slice(0,index) , updatedObj , ...prev.slice(index + 1 )]
        });
    }
      
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImages(prev => [reader.result , ...prev]);
        };
    };

    useEffect(() => {
        dispatch(getTotalAttributes(toast));
        dispatch(getTotalCategories());
        dispatch(getSuppliers(toast))
    }, []);

    useEffect(() => {
        if(!category) return ;
        dispatch(getSubCategories(category , toast));
    }, [category]);

    
    const submitHandler = async e => {
        e.preventDefault();
        const data = {
            name , description , price , unit , stock , discount , discountType ,
            attributes : selectedAttributes ,
            category , subCategory , images , owner : supplier ,
            shoeSizes : shoeSizes.map(item => item.value),
            productSizes : productSizes.map(item => item.value),
        };
       dispatch(createProduct(data , toast , navigate));
    }

    const removeImage = item => {
        setImages(prev => prev.filter(img => img !== item ))
    }
    
    return (
        <div className="sm:p-6 py-4 px-2">
            <div className="flex items-center justify-between">
                <Heading title='Add New Product' />
                <BackBtn />
            </div>
            <div>
                <form
                onSubmit={submitHandler} 
                className="flex flex-col gap-6"
                >
                    <div className='shadow-bg sm:py-6 py-4 sm:px-6 px-3 mt-6 flex flex-col gap-6'>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Name (EN)</label>
                            <input 
                            type="text" 
                            placeholder='New Product'
                            className='textField'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Short Description (EN)</label>
                            <textarea
                            type="text" 
                            placeholder='Product description...'
                            className='textField resize-none'
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            rows={8}
                            />
                        </div>
                        
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-8 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Price</label>
                            <input 
                            type="number" 
                            placeholder='Ex : 100'
                            className='textField'
                            value={price}
                            onChange={e => setPrice(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Unit</label>
                            <SelectBox 
                            options={[
                                {value : "kg" , label : "Kg"},
                                {value : "gm" , label : "Gm"},
                                {value : "ltr" , label : "ltr"},
                                {value : "pc" , label : "Pc"},
                            ]} 
                            setValue={setUnit}
                            />
                        </div>
                    </div>
                 
                    <div className="flex sm:items-center sm:gap-8 gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Discount</label>
                            <input 
                            type="number" 
                            placeholder='Ex : 7'
                            className='textField'
                            value={discount}
                            onChange={e => setDiscount(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Discount Type</label>
                            <SelectBox 
                            options={[
                                {value : "amount" , label : "Amount"},
                                {value : "percent" , label : "Percent"},
                            ]} 
                            setValue={setDiscountType}
                            />
                        </div>
                        
                    </div>
                    <div className="flex sm:items-center sm:gap-8 gap-4 sm:flex-row flex-col">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Supplier * </label>
                            {
                                supplierLoading
                                ? 
                                    <ClipLoader size={15} />
                                : 
                                <SelectBox 
                                options={suppliers?.map(item => (
                                    {value : item?._id  , label : item?.firstName + item?.lastName }
                                ))} 
                                setValue={setSupplier}
                                />
                            }
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Stock</label>
                            <input 
                            type="number" 
                            placeholder='Ex : 15'
                            className='textField'
                            value={stock}
                            onChange={e => setStock(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-8 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Category * </label>
                            <SelectBox 
                            options={categories?.map(item => (
                                { value : item?._id  , label : item?.name }
                            ))} 
                            setValue={setCategory}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Sub Category</label>
                            {
                                loading 
                                ? 
                                    <ClipLoader size={15} />
                                : 
                                <SelectBox 
                                options={subCategories?.map(item => (
                                    { value : item?._id , label : item?.name }
                                ))} 
                                setValue={setSubCategory}
                                />
                               
                            }
                        </div>
                    </div>
                    {/* MULTI SELECT */}
                    <div className="border rounded-md p-4 pb-6 mt-3">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Attributes </label>
                            <MultiSelectBox 
                            options={attributes?.map(item => (
                                { value : item?.name , label : item?.name , name : item?.name }
                            ))} 
                            setValues={setselectedAttributes}
                            values={selectedAttributes}
                            />
                        </div>
                        {
                            selectedAttributes?.length > 0 
                            ? 
                            <div className="mt-8 flex flex-col gap-4">
                                {
                                    selectedAttributes?.map((attr , i) => (
                                        <div className="flex items-center gap-8" key={i}>
                                            <div className="flex-[0.3]">
                                                <input 
                                                className="textField w-full"
                                                value={attr?.label} 
                                                readOnly
                                                />
                                            </div>
                                            <div className="flex-[0.7]">
                                                <input 
                                                type="text" 
                                                placeholder='Enter choice values'
                                                className='textField w-full'
                                                name={attr.name}
                                                onChange={handleAttrChange}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            : ''
                        }
                    </div>
                    <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Shoe Sizes</label>
                            <MultiSelectBox 
                            options={availableSizes?.map(item => (
                                { value : item , label : item , name : item }
                            ))} 
                            setValues={setShoeSizes}
                            values={shoeSizes}
                            />
                    </div>
                    <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Product Sizes</label>
                            <MultiSelectBox 
                            options={availableProductSizes?.map(item => (
                                { value : item , label : item , name : item }
                            ))} 
                            
                            setValues={setProductSizes}
                            values={productSizes}
                            />
                    </div>
                    <div>
                        <label className='text-[15px]'>Product Image* </label>
                        <input 
                        type="file"
                        onChange={handleFileChange} 
                        ref={prodImgRef} 
                        hidden 
                        />
                        <div className="flex items-center gap-4 flex-wrap">
                            {
                                images?.length > 0 && images?.map((item , i) => (
                                    <div
                                    key={i} 
                                    className="w-[200px] h-[150px] rounded-md relative"
                                    >
                                        <img 
                                        src={item} 
                                        alt="Product" 
                                        className="w-full h-full rounded-md object-cover"
                                        />
                                        <div 
                                        className="absolute top-1 right-1 text-white hover:text-red-500 rounded-sm px-1 cursor-pointer"
                                        onClick={() => removeImage(item)}
                                        >
                                            <i className="uil uil-trash"></i>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="border-2 border-dashed p-1 rounded-md mt-2 cursor-pointer w-[200px] h-[150px] flex items-center justify-center text-2xl font-bold text-gray-500"
                            onClick={() => prodImgRef?.current?.click() }
                            >
                                +
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button type="submit" className="btn-primary">
                            {
                                createProductLoading
                                ? 
                                    <ClipLoader size={20} color='$fff' />
                                : 
                                    'Submit'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddNewProduct;