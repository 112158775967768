import axios from "axios";

export const baseURL = 'https://api.raabtastore.com';
export const stroageBaseURL = 'https://raabtastore.s3.amazonaws.com';
// export const baseURL = 'http://localhost:5000';

const Axios = axios.create({
    baseURL : `${baseURL}/api` ,
});

export default Axios;