import BannersTable from 'components/banners/BannersTable';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBanners } from 'redux/actions/bannerActions';

const Banners = () => {
    const dispatch = useDispatch();
    const { loading , banners , currentPage } = useSelector(state => state.banner);

    useEffect(() => {
        dispatch(getBanners(toast));
    }, [currentPage])
    

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center gap-4'>
                <h1 className='main-heading'>
                    <i className="uil uil-list-ul"></i>
                    <span>Banner List</span>
                </h1>
            </div>
            <div className='shadow-bg  mt-6'>
                <div className='flex items-center justify-end py-6 px-4'>
                    <Link to='/banner/add-new'>
                        <div className='btn-primary flex gap-2' style={{ 
                            padding : "8px 1rem"
                        }}>
                            <i className="uil uil-plus-circle text-lg"></i>
                            <span>Add New Banner</span>
                        </div>
                    </Link>
                </div>
                {
                    loading
                    ? 
                        <Loader />
                    : 
                    banners?.length > 0 
                    ? 
                        <div>
                            <BannersTable />
                        </div>
                    : 
                        <ItemNotFound />
                }
            </div>
        </div>
    )
}

export default Banners;