import Footer from "components/global/footer";
import Header from "components/global/header";
import Sidebar from "components/global/sidebar";
import Login from "pages/auth/Login";
import { BrowserRouter as Router , Routes , Route } from "react-router-dom";
import Home from "./pages/home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Category from "pages/category";
import SubCategory from "pages/category/SubCategory";
import Attributes from "pages/attributes";
import Products from "pages/products";
import AddNewProduct from "pages/products/AddNewProduct";
import ProductDetails from "pages/products/ProductDetails";
import ProductReviews from "pages/productReviews";
import Orders from "pages/orders";
import Banners from "pages/banners";
import AddNewBanner from "pages/banners/AddNewBanner";
import Coupons from "pages/coupons";
import AddNewCoupon from "pages/coupons/AddNewCoupon";
import SupplierRegister from "pages/supplier/SupplierRegister";
import SupplierList from "pages/supplier";
import SupplierReviews from "pages/supplier/SupplierReviews";
import CustomersList from "pages/customersList";
import SubscribedEmail from "pages/subscribedEmail";
import Notifications from "pages/notification";
import SendNotification from "pages/notification/SendNotification";
import Messages from "pages/messages";
import EarningReport from "pages/reports/EarningReport";
import { useState } from "react";
import ProductReport from "pages/reports/ProductReport";
import SupplierReport from "pages/reports/SupplierReport";
import ProtectedRoute from "ProtectedRoute";
import UpdateAttribute from "pages/attributes/UpdateAttribute";
import EditProduct from "pages/products/EditProduct";
import CustomerDetails from "pages/customersList/CustomerDetails";
import SocialMedia from "pages/settings/SocialMedia";
import ReturnPolicy from "pages/settings/ReturnPolicy";
import RefundPolicy from "pages/settings/RefundPolicy";
import CancellationPolicy from "pages/settings/CancellationPolicy";
import TermsAndConditions from "pages/settings/TermsAndConditions";
import PrivacyPolicy from "pages/settings/PrivacyPolicy";
import About from "pages/settings/About";
import OrderDetails from "pages/orders/OrderDetails";
import EcommerceSetup from "pages/settings/EcommerceSetup";
import TextBanner from "pages/textBanner";
import LoadSheetReport from "pages/reports/loadSheets";
import WithdrawRequestsPage from "pages/withdraw/WithdrawRequestsPage";
import AddCatelog from "pages/catelog/addCatelog";
import AddSection from "pages/sections/addSection";
import Catelog from "pages/catelog";
import Section from "pages/sections";
import EditSection from "pages/sections/editSection";

function App() {
    const [isLoginPage , setIsLoginPage] = useState(false);

    return (
        <div className="space">
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={3000}
                hideProgressBar
                closeOnClick
                pauseOnHover
            />
            <Router>
                <Header />
                { !isLoginPage && <Sidebar /> }
                <Routes>
                    <Route 
                    path="/" 
                    element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    } />
                    <Route 
                    path='/login' 
                    element={<Login setIsLoginPage={setIsLoginPage} />} 
                    />
                    <Route 
                    path='/categories/main' 
                    element={<Category />} 
                    />
                    <Route 
                    path='/categories/sub' 
                    element={<SubCategory />} 
                    />
                    <Route 
                    path='/attribute' 
                    element={<Attributes />} 
                    />
                    <Route 
                    path="/attribute/edit/:id" 
                    element={
                        <ProtectedRoute>
                            <UpdateAttribute />
                        </ProtectedRoute>
                    } />
                    <Route 
                    path='/products' 
                    element={<Products />} 
                    />
                    <Route 
                    path='/product/add-new' 
                    element={<AddNewProduct />} 
                    />
                    <Route 
                    path='/product/edit/:id' 
                    element={<EditProduct />} 
                    />
                    <Route 
                    path='/product/details/:id' 
                    element={<ProductDetails />} 
                    />
                    <Route 
                    path='/product-reviews' 
                    element={<ProductReviews />} 
                    />
                    <Route path='/orders' element={<Orders />} />
                    <Route path='/banners' element={<Banners />} />
                    <Route path='/text-banner' element={<TextBanner />} />
                    <Route path='/banner/add-new' element={<AddNewBanner />} />
                    <Route path='/coupon' element={<Coupons />} />
                    <Route path='/coupon/add-new' element={<AddNewCoupon />} />
                    <Route 
                    path='/supplier/register' 
                    element={<SupplierRegister />} 
                    />
                    <Route 
                    path='/supplier/edit' 
                    element={<SupplierRegister />} 
                    />
                    <Route 
                    path='/supplier/list'
                    element={<SupplierList />} 
                    />
                    <Route 
                    path='/supplier/reviews' 
                    element={<SupplierReviews />}
                    />
                    <Route 
                    path='/customer-list' 
                    element={<CustomersList />} 
                    />
                    <Route 
                    path='/customer/:id' 
                    element={<CustomerDetails />} 
                    />
                    <Route 
                    path='/subscribed-emails' 
                    element={<SubscribedEmail />} 
                    />
                    <Route 
                    path='/notifications' 
                    element={<Notifications />} 
                    />
                    <Route 
                    path='/send-notification' 
                    element={<SendNotification />} 
                    />
                    <Route 
                    path='/add-catelog' 
                    element={<AddCatelog />} 
                    />
                    <Route 
                    path='/catelog' 
                    element={<Catelog />} 
                    />
                    <Route 
                    path='/add-section' 
                    element={<AddSection />} 
                    />
                    <Route 
                    path='/section' 
                    element={<Section />} 
                    />
                    <Route 
                    path='/section/edit/:id' 
                    element={<EditSection />} 
                    />
                    <Route 
                    path='/messages'
                    element={<Messages />} 
                    />
                    <Route 
                    path='/report/earning-report'
                    element={<EarningReport />}
                    />
                    <Route 
                    path='/report/product-report'
                    element={<ProductReport />}
                    />
                    <Route 
                    path='/report/supplier-report'
                    element={<SupplierReport />}
                    />
                    <Route 
                    path='/report/generated-loadsheet'
                    element={<LoadSheetReport />}
                    />
                    <Route 
                    path='/withdrawRequests'
                    element={<WithdrawRequestsPage />}
                    />
                    <Route 
                    path='/settings/social-media'
                    element={<SocialMedia />}
                    />
                    <Route 
                    path='/settings/return-policy'
                    element={<ReturnPolicy />}
                    />
                    <Route 
                    path='/settings/refund-policy'
                    element={<RefundPolicy />}
                    />
                    <Route 
                    path='/settings/cancellation-policy'
                    element={<CancellationPolicy />}
                    />
                    <Route 
                    path='/settings/terms-and-condition'
                    element={<TermsAndConditions />}
                    />
                    <Route 
                    path='/settings/privacy-policy'
                    element={<PrivacyPolicy />}
                    />
                    <Route 
                    path='/settings/about'
                    element={<About />}
                    />
                    <Route 
                    path='/order/:orderId'
                    element={<OrderDetails />}
                    />
                    <Route 
                    path='/settings/ecommerce-setup'
                    element={<EcommerceSetup />}
                    />
                </Routes>
                { !isLoginPage &&  <Footer />}
            </Router>
        </div>
    );
}

export default App;
