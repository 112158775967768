import Select from 'react-select';


const MultiSelectBox = ({ options , setValues , values }) => {
    const handleChange = (selectedOptions) => {
        setValues(selectedOptions);
    }
    
    return (
        <Select
            options={options?.filter((option) => !values?.some((v) => v.name === option.name))}
            className='outline-none focus:border-red-500'
            inputId='select-box-input'
            placeholder='---Select---'
            isMulti 
            onChange={handleChange}
            value={values}
        />
    )
}

export default MultiSelectBox