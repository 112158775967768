import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Loader from 'components/global/Loader'
import Search from 'components/global/Search'
import SupplierListTable from 'components/suppliers/SupplierListTable'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSuppliers } from 'redux/actions/supplierActions'

const SupplierList = () => {
    const dispatch = useDispatch();
    const { suppliers , currentPage , loading } = useSelector(state => state.supplier); 

    useEffect(() => {
        dispatch(getSuppliers(toast));
    }, [currentPage]);

    return (
        <div className='sm:p-6 px-2 py-4'>
            <div className='flex items-center gap-2'>
                <Heading title='Supplier List' icon={false} />
                <span className='text-primary font-semibold text-2xl'>5</span>
            </div>
            <div className='shadow-bg  mt-6'>
                <div className='flex sm:items-center sm:flex-row flex-col justify-between sm:py-6 py-4 sm:px-4 px-2 gap-4'>
                    <form>
                        <Search />
                    </form>
                    <Link to='/supplier/register'>
                        <div className='btn-primary p-sm w-fit flex gap-2'>
                            <i className="uil uil-plus-circle text-lg"></i>
                            <span>Add New Supplier</span>
                        </div>
                    </Link>
                </div>
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    suppliers?.length > 0 
                    ? 
                        <div>
                            <SupplierListTable />
                        </div>
                    : 
                        <ItemNotFound />
                }
            </div>
        </div>
    )
}

export default SupplierList