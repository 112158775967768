import Heading from 'components/global/Heading';
import Chatbox from 'components/messages/Chatbox';
import Conversations from 'components/messages/Conversations';
import { useState } from 'react';

const Messages = () => {
    const [selectedChat , setSelectedChat] = useState({});

    return (
        <div className='p-6'>
            <div>
                <Heading title='Conversations' icon={false} />
            </div>
            <div className='flex mt-6 gap-8'>
                <div className='flex-[0.3]'>
                    <Conversations 
                    selectedChat={selectedChat} 
                    setSelectedChat={setSelectedChat} 
                    />
                </div>
                <div className='flex-[0.7] w-full h-full'>
                    <Chatbox selectedChat={selectedChat} />
                </div>
            </div>
        </div>
    )
}

export default Messages