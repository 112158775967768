import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Img1 from 'assets/images/img1.jpg';
import ReviewRating from 'components/products/ReviewRating';
import ReviewsTable from 'components/products/ReviewsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetails } from 'redux/actions/productActions';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import { stroageBaseURL } from 'config/api';
import { getSingleProductReviews } from 'redux/actions/ProductReviewsActions';


const ProductDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { loading , productDetails : { stats , product } } = useSelector(state => state.product);

    const { currentPage , loading : productReviewsLoading , reviews } = useSelector(state => state.productReview);

    useEffect(() => {
        dispatch(getProductDetails(id , toast))
    }, [id]);

    useEffect(() => {
        dispatch(getSingleProductReviews(id , toast))
    }, [currentPage]);

    return (
        <div className='sm:p-6 py-4 px-2'>
            {
                loading 
                ? 
                    <Loader />
                : 
                product
                ? 
                    <div>
                        <div className='flex items-center justify-between '>
                            <h3 className='main-heading'>{product?.name}</h3>
                            <button className="btn-primary" 
                            onClick={() => navigate(-1)}
                            >
                                Back
                            </button>
                        </div>
                        {/* PRODUCT REVIEWS */}
                        <div className='mt-6'>
                            <h4 className='text-primary'>Product Reviews</h4>
                            <div className='shadow-bg sm:p-5 py-3 px-3 mt-2'>
                                <div className='flex lg:items-center lg:flex-row flex-col gap-6 border-b pb-6'>
                                    <div className='flex-[0.4] flex items-center gap-4'>
                                        <div className='flex-1'>
                                            <img 
                                            src={
                                                product 
                                                ? 
                                                stroageBaseURL + '/products/' + product?.images[0] 
                                                : 
                                                Img1
                                            } 
                                            alt="Product" 
                                            className='w-[200px] h-[170px] object-cover rounded-md'
                                            />
                                        </div>
                                        <div className='flex-1'>
                                            <span className='text-5xl font-semibold'>
                                                {stats?.total}
                                            </span>
                                            <p className='mt-2 text-grayText'>Reviews</p>
                                        </div>
                                    </div>
                                    <div className='flex-[0.6]'>
                                        <ReviewRating />
                                    </div>
                                </div>

                                <div className='mt-6 flex sm:items-center sm:flex-row flex-col gap-8'>
                                    <div className='flex-[0.4]'>
                                        <p>{product?.name}</p>
                                        <div className='border p-4 rounded-md'>
                                            <div className='bg-green-100 text-green-500 px-2 text-xs rounded-sm py-[2px] w-fit '>Total Stock : {product?.stock}</div>
                                            <div className='mt-2 text-[15px] text-grayText flex flex-col gap-1'>
                                                <p>Price :  {product?.price} PKR / {product?.unit}</p>
                                                <p>Discount : {product?.discount || 0} PKR</p>
                                                <p>Shoe Sizes : {product?.shoeSizes.toString() || ""} </p>
                                                <p>Product Sizes : {product?.productSizes.toString() || ""} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-[0.6]'>
                                        <h6 className='text-lg font-semibold mb-2'>Short Description :</h6>
                                        <p>{product?.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : 
                    <ItemNotFound />
            }

            {/* REVIEWS TABLE */}
            {
                productReviewsLoading
                ? 
                    <Loader />
                : 
                reviews?.length > 0 
                ?
                    <div>
                        <ReviewsTable />
                    </div>
                : 
                    <ItemNotFound />
            }
            
        </div>
    )
}

export default ProductDetails