import Axios from 'config/api';
import { setLoading, setUser } from 'redux/reducers/authReducer';


export const login = (data , navigate , toast) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { message , doc , token } } } = await Axios.post('/admin/login' , data );
        dispatch(setUser({...doc , token}));
        localStorage.setItem('user' , JSON.stringify({...doc , token}));
        dispatch(setLoading(false));
        navigate('/');
        toast.success(message);
    } catch (err) {
        dispatch(setLoading(false));
        console.log('login error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
    }
}

export const logout = (navigate , toast) => async(dispatch , getState) => {
    const { token } = getState().auth.user;
    dispatch(setLoading(true));
    try {
        Axios('/admin/logout' , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setUser(null));
        localStorage.setItem('user' , null);
        dispatch(setLoading(false));
        navigate('/login');
        toast.success('Logged out successfully.')
    } catch (err) {
        dispatch(setLoading(false));
        console.log('logout error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
    }
}