import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useClickOutside from 'utils/clickOutside';
import { stroageBaseURL } from 'config/api';
import { deleteCategory } from 'redux/actions/categoryActions';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import Pagination from 'components/global/pagination';
import { setCurrentPage } from 'redux/reducers/categoryReducer';



const MainCategoryTable = ({ setEditItem  , toggleAddCatPopup }) => {
    const dropMenuRef = useRef(null);
    const dispatch = useDispatch();
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    const { categories , deleteLoading, } = useSelector(state => state.category);
    const { loading ,  pages , currentPage } = useSelector(state => state.category);



    useClickOutside(dropMenuRef , () => setShowDropMenu(false));

    const editHandler = (item) => {
        setEditItem(item);
        toggleAddCatPopup(true);
    }

    const deleteHandler = async catId => {
        if(window.confirm('Are you sure? you want to delete this item?')){
            await dispatch(deleteCategory(catId , toast));
            setShowDropMenu(false);
        }
    }

    return (

        // <div className="overflow-x-auto bg-pure mt-8 mb-6 rounded-lg border shadow-md pb-20">
            
        <table className="w-full table-auto">
            <thead className="bg-lightSlate border-b">
                <tr>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        #
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        NAME
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        Image
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                        STATUS
                    </th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                        ACTION
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    categories?.map((item , i) => (
                        <tr
                        key={item?._id} 
                        className="bg-white border-b transition duration-300 ease-in-out">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{i+1}</td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {item?.name}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <div>
                                <img 
                                src={`${stroageBaseURL}/categories/${item?.image}`} 
                                alt={item?.name}
                                className='w-[100px] rounded-md object-cover' 
                                />
                            </div>
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            <p className='border py-2.5 px-3 flex items-center gap-2 cursor-pointer'>
                                <div 
                                className={`${
                                    item?.status === 1 ? 'bg-green-500' : 'bg-red-500'} 
                                    rounded-full w-[7px] h-[7px] `}>
                                </div>
                                <span>
                                    {item?.status === 1 ? 'Active' : 'Disabled'}
                                </span>
                            </p>
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                            <div className='flex items-end justify-center relative' 
                            >  
                                <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowDropMenu(prev => !prev);
                                    setSelectedMenuIndex(i);
                                }}
                                >
                                    <div><i className="uil uil-setting"></i></div>
                                    <div><i className="uil uil-angle-down"></i></div>
                                </div>
                                {/* DROP MENU */}
                                {   
                                    showDropMenu && selectedMenuIndex === i &&
                                    <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                    ref={dropMenuRef}
                                    >
                                        <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                        onClick={() => editHandler(item)}
                                        >
                                            Edit
                                        </div>
                                        <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                        onClick={() => deleteHandler(item?._id)}
                                        >
                                            {
                                                deleteLoading
                                                ? 
                                                    <ClipLoader size={15} color='#000' />
                                                : 
                                                    'Delete'
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>
                    ))
                }
            </tbody>
        </table>
        /* <Pagination 
            pageCount={pages}
            currentPage={currentPage}
            setPage={setCurrentPage}
            />
        </div> */
    )
}

export default MainCategoryTable;