import { stroageBaseURL } from 'config/api';
import moment from 'moment';
import { forwardRef, useEffect, useState } from 'react'

const SupplierReportTable = forwardRef(({ report } , ref ) => {
   
    
    return (
        <div className="overflow-x-auto rounded-lg rep-container" ref={ref} >
            {
            report?.orders?.map((item, i) => (
                <div
                key={item?._id}
                className="page-break report-row"
                style={{
                    pageBreakInside: 'avoid',
                    breakAfter: 'always',
                }}
                >
                    <div className='text-center '>
                        <h1 className='text-xl font-bold'>
                            Rabta Store Supplier Order Sheet
                        </h1>
                        <h3 className='font-bold text-[15px]'>Supplier : {report?.supplier?.firstName + " " + report?.supplier?.lastName} </h3>
                    </div>
                    <div className='flex items-center justify-center'>
                        <div className='flex gap-6 py-14 px-20'>
                            <div className='flex flex-col gap-2'>
                                <p>Quantity : {item?.totalProductQty}</p>
                                <p>Order Price : {item?.orderAmount}</p>
                                {item?.orderItems.shoeSizes&&<p className='font-medium text-[15px]'>
                                    Shoe Size : {item?.orderItems.shoeSizes}
                                </p>}
                               {item?.orderItems.productSize&& <p className='font-medium text-[15px]'>
                                    Product Size : {item?.orderItems.productSize}
                                </p>}
                            </div>
                            <div className=''>
                                <img 
                                src={stroageBaseURL + "/products/" + item?.product?.images[0]} 
                                alt={item?.product?.name}
                                className='w-[120px] h-auto object-cover' 
                                />
                            </div>
                            <div className='flex flex-col gap-0.5 flex-[1]'>
                                <p className='font-medium text-[15px]'>
                                    Order Id : {item?.customOrderId}
                                </p>
                                <p className='font-medium text-[15px]'>
                                    Product Name : {item?.product?.name}
                                </p>
                                
                                <p className='font-medium text-[15px]'>
                                    cn_number : {item?.trackingNo}
                                </p>
                                <p className='font-medium text-[15px]'>
                                    Description : {item?.product?.description}
                                </p>
                                <p >
                                    Note: There might be 1-3 cm errors of dimension data due to pure manual measureThere might be slight color differences due to different light and monitor effects.
The Item colors will match the open picture.</p>
                            </div>
                        </div>

                    
                    </div>
                </div>
            ))
            }
        </div>
    )
})

export default SupplierReportTable;











