import Heading from "components/global/Heading";
import Search from "components/global/Search";



const SubscribedEmail = () => {
    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center gap-2'>
                <Heading title='Subscribed Customers' icon={false} />
                <div className="total-count translate-y-1">
                    12
                </div>
            </div>
            <div className='mt-6 shadow-bg'>
                <div className="flex justify-end p-6">
                    <Search />
                </div>
                <div className="overflow-x-auto rounded-lg">
                    <table className="w-full table-auto overflow-x-auto ">
                        <thead className="bg-lightSlate border-b text-[13px]">
                            <tr>
                                <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                                    #
                                </th>
                                <th scope="col" className=" font-medium text-gray-900 px-6 py-4 text-left">
                                    EMAIL
                                </th>
                            
                            </tr>
                        </thead>
                        <tbody className='text-[13px]'>
                        {
                                [...Array(6).keys()].map((item , i) => (
                                    <tr className="bg-white border-b transition duration-300 ease-in-out">
                                        <td className="px-6 py-4 whitespace-nowrap  font-medium text-gray-900">{i}</td>
                                        <td className=" text-gray-900 px-6 py-4 whitespace-nowrap">
                                            shariful@gmail.com  
                                        </td>
                                
                                    </tr>
                                ))
                        }
                        
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default SubscribedEmail;