import Heading from 'components/global/Heading';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { createAttribute , deleteAttribute , getAttributes } from 'redux/actions/attributeActions';
import useClickOutside from 'utils/clickOutside';

const Attributes = () => {
    const navigate = useNavigate();
    const dropMenuRef = useRef(null);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const { loading , attributes } = useSelector(state => state.attributes);
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    const [name , setName] = useState('');

    useClickOutside(dropMenuRef , () => setShowDropMenu(false));

    const submitHandler = async (e) => {
        e.preventDefault();
        dispatch(createAttribute({ name } , setName , toast ));
    };

    useEffect(() => {
        dispatch(getAttributes(toast));
    } , []);

    const editHandler = (item) => {
        navigate(`/attribute/edit/${item?._id}`)
    }

    const deleteHandler = (item) => {
        dispatch(deleteAttribute(item._id , toast));
        setShowDropMenu(false);
    }

    return (
        <div className='sm:p-6 py-4 px-2 min-h-screen h-full'>
            <div className='shadow-bg p-5'>
                <Heading title='Add New Attribute' />
                <div className='mt-6 pt-6 border-t'>
                    <form onSubmit={submitHandler}>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Name (EN)</label>
                            <input 
                            type="text" 
                            placeholder='New Attribute'
                            className='textField'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            ref={inputRef}
                            />
                        </div>
                        <div className='mt-6'>
                            <button 
                            className="btn-primary disabled:cursor-not-allowed"
                            disabled={loading}
                            >
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    : 
                                        'SUBMIT'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='shadow-bg mt-12'>
                <div className='py-4 px-4'>
                    <h1 className='font-semibold text-2xl flex items-center gap-2'>
                        <i className="uil uil-moon-eclipse"></i>
                        <span>All Attributes</span>
                    </h1>
                </div>
                {
                    loading 
                    ? 
                        <div className='w-full h-[200px] flex items-center justify-center'>
                            <ClipLoader size={20} color='#000' />
                        </div>
                    :
                    attributes?.length > 0 
                    ? 
                    <table className="w-full table-auto">
                        <thead className="bg-lightSlate border-b">
                            <tr>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                    #
                                </th>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                    NAME
                                </th>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-right">
                                    ACTION
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                                attributes?.map((item , i) => (
                                    <tr 
                                    className="bg-white border-b transition duration-300 ease-in-out"
                                    key={item?._id}
                                    >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {i+1}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                        {item?.name}
                                    </td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        <div className='flex items-end justify-end relative' 
                                        >  
                                            <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowDropMenu(prev => !prev);
                                                setSelectedMenuIndex(i);
                                            }}
                                            >
                                                <div><i className="uil uil-setting"></i></div>
                                                <div><i className="uil uil-angle-down"></i></div>
                                            </div>
                                            {/* DROP MENU */}
                                            {   
                                                showDropMenu && selectedMenuIndex === i &&
                                                <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex justify-start flex-col'
                                                ref={dropMenuRef}
                                                >
                                                    <div 
                                                    className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                                    onClick={() => editHandler(item)}
                                                    >
                                                        Edit
                                                    </div>
                                                    <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                                    onClick={() => deleteHandler(item)}
                                                    >
                                                        Delete
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </td>
                                </tr>
                                ))
                        }
                        
                        </tbody>
                    </table>
                    : 
                        <div className='w-full h-[200px] flex items-center justify-center text-2xl font-semibold'>
                            <h3>No Data found.</h3>
                        </div>

                }
            </div>

        </div>
    )
}

export default Attributes