import Pagination from 'components/global/pagination';
import { stroageBaseURL } from 'config/api';
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { deleteBanner } from 'redux/actions/bannerActions';
import { setCurrentPage } from 'redux/reducers/bannerReducer';
import useClickOutside from 'utils/clickOutside';



const pImg = 'https://img.freepik.com/free-psd/new-collection-fashion-sale-web-banner-template_120329-1507.jpg';

const BannersTable = () => {
    const dropMenuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDropMenu , setShowDropMenu] = useState(false);
    const [selectedMenuIndex , setSelectedMenuIndex]  = useState(0);
    const { banners , pages , currentPage , deleteLoading } = useSelector(state => state.banner);
    
    useClickOutside(dropMenuRef , () => setShowDropMenu(false));

    const editHandler = (item) => {
        localStorage.setItem('editBanner' , JSON.stringify(item));
        navigate(`/banner/add-new?edit=true&banner=${item._id}`);
    }

    const deleteHandler = async id => {
        if(window.confirm('Are you sure? You want to delete this item?')){
            await dispatch(deleteBanner(id , toast));
            setShowDropMenu(false);
        }
    }

    return (
        <div className="overflow-x-auto bg-pure  border  pb-20">
            <table className="w-full table-auto">
                <thead className="bg-lightSlate border-b">
                    <tr>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            #
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            TITLE
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            IMAGE
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                            Type
                        </th>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                            ACTION
                        </th>
                    </tr>
                </thead>
                <tbody>
                   {
                        banners?.map((item , i) => (
                            <tr 
                            className="bg-white border-b transition duration-300 ease-in-out"
                            key={item?._id}
                            >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{i+1}</td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?.name}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                <div>
                                    <img 
                                    src={`${stroageBaseURL}/banners/${item?.image}`} 
                                    alt="Product" 
                                    className='w-[140px] h-[80px] object-cover rounded-md'
                                    />
                                </div>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {item?.itemType}
                            </td>
                           
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                                <div className='flex items-end justify-center relative' 
                                >  
                                    <div className='bg-gray-500 py-1.5 px-4 flex items-center rounded-md text-pure gap-2 text-lg w-fit cursor-pointer'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropMenu(prev => !prev);
                                        setSelectedMenuIndex(i);
                                    }}
                                    >
                                        <div><i className="uil uil-setting"></i></div>
                                        <div><i className="uil uil-angle-down"></i></div>
                                    </div>
                                    {/* DROP MENU */}
                                    {   
                                        showDropMenu && selectedMenuIndex === i &&
                                        <div className='absolute top-10  bg-pure shadow-lg w-[120px] h-auto rounded-lg z-[50] border flex flex-col'
                                        ref={dropMenuRef}
                                        >
                                            <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                            onClick={() => editHandler(item)}
                                            >
                                                Edit
                                            </div>
                                            <div className='py-3 font-medium hover:bg-gray-100 px-4 cursor-pointer'
                                            onClick={() => deleteHandler(item?._id)}
                                            >
                                                {
                                                    deleteLoading
                                                    ? 
                                                        <ClipLoader size={20} color='#fff' />
                                                    : 
                                                        'Delete'
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        ))
                   }
                
                </tbody>
            </table>
            <div>
                <Pagination 
                pageCount={pages} 
                currentPage={currentPage}
                setPage={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default BannersTable