import { createSlice } from "@reduxjs/toolkit";

const attributeSlice = createSlice({
    name : 'attributes',
    initialState : {
        attributes : [] ,
        loading : false
    } ,
    reducers : {
        addAttribute(state , action) {
            state.attributes.unshift(action.payload)
        } ,
        setAttributes(state , action){
            state.attributes = action.payload;
        } ,
        removeAttribute(state , action){
            state.attributes = state.attributes.filter(item => item._id !== action.payload)
        } ,
        setLoading(state , action){
            state.loading = action.payload;
        } , 
        replaceAttribute (state , action){
            const index = state.attributes.findIndex(item => item._id === action.payload._id );
            state.attributes[index] = action.payload;
        }
    }
});

export const { 
    addAttribute , removeAttribute , setLoading , setAttributes , replaceAttribute
} = attributeSlice.actions;
export default attributeSlice.reducer;