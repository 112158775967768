import Heading from 'components/global/Heading'
import Axios from 'config/api';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { updateAttribute } from 'redux/actions/attributeActions';

const UpdateAttribute = () => {
    const [name , setName] = useState('');
    const navigate = useNavigate();
    const { loading } = useSelector(state => state.attributes);
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { id } = useParams();

    const submitHandler = async (e) => {
        e.preventDefault();
        dispatch(updateAttribute(id , { name } , toast , navigate ));
    };

    useEffect(() => {
        const fetchAttribute = async () => {
            try {
                const { data : { data : { doc } } } = await Axios(`/attributes/${id}` , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                setName(doc?.name);
            } catch (err) {
                console.log('get Attribute error' , err);
                toast.error('Something went wrong.')
            }
        };
        fetchAttribute()
    } , []);


    return (
        <div className='sm:p-6 py-4 px-2 min-h-screen h-full'>
             <div className='shadow-bg p-5'>
                <Heading title='Update Attribute' />
                <div className='mt-6 pt-6 border-t'>
                    <form onSubmit={submitHandler}>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Name (EN)</label>
                            <input 
                            type="text" 
                            placeholder='New Attribute'
                            className='textField'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className='mt-6'>
                            <button 
                            className="btn-primary disabled:cursor-not-allowed"
                            disabled={loading}
                            >
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    : 
                                        'UPDATE'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateAttribute