import Axios from "config/api";
import { 
    addProduct, removeProduct, setProducts, setCreateLoading, setDeleteLoading , updateProduct , setLoading, setUpdateLoading, setPages, setCurrentPage, setSingleProduct, setEditItem, setProductDetails, setProductReport, setProductReportLoading
} from "redux/reducers/productReducer";

const endPoint = '/products'

export const createProduct = (data , toast , navigate) => async (dispatch , getState) => {
    try {
        dispatch(setCreateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.post(`${endPoint}` , data , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(addProduct(doc));
        dispatch(setCreateLoading(false));
        navigate('/products');
        toast.success('Product created successfully.');
    } catch (err) {
        dispatch(setCreateLoading(false));
        console.log('Create Product error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const _updateProduct = (id , data , toast , navigate) => async (dispatch , getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`${endPoint}/${id}` , data  , 
        { headers : { Authorization : `Bearer ${token}`} });
        dispatch(updateProduct(doc));
        dispatch(setUpdateLoading(false));
        navigate('/products');
        toast.success('Updated Successfully.');
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update Product error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}
export const _approveProduct = (id , data , toast , navigate) => async (dispatch , getState) => {
    try {
        dispatch(setUpdateLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.put(`${endPoint}/approve/${id}` , data  , 
        { headers : { Authorization : `Bearer ${token}`} });
        dispatch(updateProduct(doc));
        dispatch(setUpdateLoading(false));
        navigate('/products');
        toast.success('Updated Successfully.');
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update Product error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const getProducts = (toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs , pages , page } } } = await Axios(`${endPoint}/get/allForAdmin?page=${getState().product.currentPage}` , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setProducts(docs));
        dispatch(setPages(pages));
        dispatch(setCurrentPage(page));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Products error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getProductsForBanner = (toast ) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { docs } } } = await Axios(`${endPoint}/for-banner` , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        dispatch(setProducts(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Banner Products error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const deleteProduct = (id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setDeleteLoading(true));
        const { token } = getState().auth.user;
        await Axios.delete(`${endPoint}/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(removeProduct(id));
        dispatch(setDeleteLoading(false));
        toast.success('Deleted Successfully.');
    } catch (err) {
        dispatch(setDeleteLoading(false));
        console.log('Delete Product error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getSingleProduct = (id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.get(`${endPoint}/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(setSingleProduct(doc));
        dispatch(setEditItem(doc));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Single Product error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getProductDetails = (id , toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { doc } } } = await Axios.get(`${endPoint}/stats/${id}` , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(setProductDetails(doc));
        dispatch(setEditItem(doc));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get Product Details error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}

export const getAllProducts = (toast) => async (dispatch , getState) => {
    try {
        dispatch(setLoading(true));
        const { data : { data : { docs } } } = await Axios(`${endPoint}/get/all`);
        dispatch(setProducts(docs));
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('Get All Products error:' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}


export const getProductReport = (data , toast) => async (dispatch , getState) => {
    try {
        dispatch(setProductReportLoading(true));
        const { token } = getState().auth.user;
        const { data : { data : { report } } } = await Axios.post(`${endPoint}/get/report` , data , 
            { headers : { Authorization : `Bearer ${token}`} }
        );
        dispatch(setProductReport(report));
        dispatch(setProductReportLoading(false));
    } catch (err) {
        dispatch(setProductReportLoading(false));
        console.log('Get Product report error' , err);
        toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
    }
}
