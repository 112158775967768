import ItemNotFound from "components/global/ItemNotFound";
import Loader from "components/global/Loader";
// import Search from "components/global/Search";
import OrdersTable from "components/orders/OrdersTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getOrders } from "redux/actions/orderActions";
import { setOrderStatus } from "redux/reducers/orderReducer";


const filterOptions = [
    { value : "all" , label : 'All'} ,
    { value : "pending" , label : 'Pending'} ,
    { value : "confirmed" , label : 'Confirmed'} ,
    { value : "processing" , label : 'Processing'} ,
    { value : "delivered" , label : 'Delivered'} ,
    { value : "returned" , label : 'Returned'} ,
    { value : "failed" , label : 'Failed'} ,
    { value : "canceled" , label : 'Canceled'} ,
]

const Orders = () => {
    const dispatch = useDispatch();
    const { orders , loading , currentPage , docsCount , orderStatus  } = useSelector(state => state.order)

    useEffect(() => {
        dispatch(getOrders(toast));
    }, [currentPage , orderStatus]);

    const filterChangeHandler = e => {
        dispatch(setOrderStatus(e.target.value));
    }

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className="flex items-center justify-between pr-4">
                <div className='flex items-center gap-4'>
                    <h1 className='main-heading'>
                        <span>Orders</span>
                    </h1>
                    <div className='total-count'>
                        {docsCount}
                    </div>
                </div>
                <div>
                    <select 
                    className="border border-cyan-500 outline-none py-1.5 sm:px-2 rounded-sm sm:w-[200px] w-fit"
                    onChange={filterChangeHandler}
                    >
                        {
                            filterOptions?.map((op,i) => (
                                <option key={i} value={op?.value}>
                                    {op?.label}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="shadow-bg mt-8">
                {
                    loading 
                    ? 
                        <Loader />
                    : 
                    orders?.length > 0
                    ?
                        <>
                            <div>
                                <OrdersTable />
                            </div> 
                        </>
                    : 
                        <ItemNotFound />
                }
            </div>
        </div>
    )
}

export default Orders;