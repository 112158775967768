import Heading from "components/global/Heading"
import Axios from "config/api";
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";


const TextBanner = () => {
    const textareaRef = useRef(null);
    const [text , setText] = useState('');
    const [loading , setLoading] = useState(false);
    const { user } = useSelector(state => state.auth);

    useEffect(()=> {
        const fetchBanner = async () => {
            try {
                setLoading(true)
                const { data : { data : { doc } } } = await Axios('/text-banner');
                setText(doc?.text);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err?.response?.data?.message || err?.message || 'something went wrong.')
            }
        }
        fetchBanner();
    }, [])
    
    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { data : { data : { doc } } } = await Axios.post('/text-banner' , { text } , {
                headers : {
                    Authorization : `Bearer ${user.token}`
                }
            });
            setText(doc?.text);
            toast.success('Saved successfully.')
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message || err?.message || 'something went wrong.')
        }
    }

    return (
        <div className='sm:p-6 py-4 px-2 min-h-screen h-full'>
            <div className='shadow-bg p-5'>
                <Heading title='Text Banner' />
                <div className='mt-6 pt-6 border-t'>
                    <form onSubmit={submitHandler}>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Banner Text</label>
                            <textarea 
                            type="text" 
                            placeholder='Type here...'
                            className='textField resize-none h-[150px]'
                            value={text}
                            onChange={e => setText(e.target.value)}
                            ref={textareaRef}
                            />
                        </div>
                        <div className='mt-6'>
                            <button 
                            className="btn-primary disabled:cursor-not-allowed"
                            disabled={loading}
                            >
                                {
                                    loading 
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    : 
                                        'Save'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>  
    )
}

export default TextBanner