import UserReportSvg from 'assets/svgs/userReport.svg';
import DatePicker_ from 'components/global/Datepicker';
import SelectBox from 'components/global/SelectBox';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Pdf from 'assets/svgs/pdf.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts, getProductReport } from 'redux/actions/productActions';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import { useReactToPrint } from 'react-to-print/lib';
import SupplierReportTable from 'components/reports/SupplierReportTable';
import Axios, { stroageBaseURL } from 'config/api';
import { getSupplierReport } from 'redux/actions/supplierActions';
import PDFMergeButton from 'components/reports/PDFMergeButton';
import useClickOutside from 'utils/clickOutside';
import useToggle from 'hooks/useToggle';





const LoadSheetReport = () => {
    const componentRef = useRef(null);
    const [showAddSubCatPopup , toggleAddSubCatPopup] = useToggle(false);
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [startDate , setStartDate] = useState('');
    const [endDate , setEndDate] = useState('');
    const [supplier , setSupplier] = useState('');
    const [suppliers , setSuppliers] = useState([]);
    const [loading , setLoading] = useState(false);
    const [sheetLoading , setsheetLoading] = useState(false);
    const [leopardSlipLoading , setLeopardSlipLoading] = useState(false);
    const [loadSheetLoading , setLoadSheetLoading] = useState(false);
    const [supplierReportLoading , setSupplierReportLoading] = useState(false);
    const [supplierReport , setSupplierReport] = useState([]);
    const { user } = useSelector(state => state.auth);
    const [showPopup, setShowPopup] = useState(false);
    const [products, setProducts] = useState([]);


    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                setLoading(true);
                const { data : { data : { docs } } } = await Axios(`/suppliers/get/all`);
                setSuppliers(docs);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err?.response?.data?.message || err?.message || 'something went wrong.')
            }
        };
        fetchSuppliers();
    } , []);
    useClickOutside(formRef , () =>  {
        toggleAddSubCatPopup();
        setShowPopup(false);
        // setImage(null);
    })
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!supplier) return alert('Please select supplier.')
        const reportData = { startDate , endDate , supplierId : supplier };
        try {
            setSupplierReportLoading(true);
            const { data : { data : { loadSheets } } } = await Axios.post(`suppliers/getAllLoadSheets` , reportData , 
                { headers : { Authorization : `Bearer ${user?.token}`} }
            );
            console.log(loadSheets);
            setSupplierReport(loadSheets)
            setSupplierReportLoading(false)
        } catch (err) {
            setSupplierReportLoading(false)
            console.log('get Supplier Report error' , err);
            toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.');
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef?.current,
    });


    const [leopardSlipLinks , setLeopardSlipLinks] = useState([]);

    useEffect(() => {
        if(supplierReport) {
            setLeopardSlipLinks(() => supplierReport?.orders?.map(item => (item?.leopardSlipLink)))
        }
    } , [supplierReport]);


    const mergeAndDownloadPDFs = async () => {
        try {
            setLeopardSlipLoading(true);
            const response = await Axios.get('/suppliers/merge-pdfs', {
                params: {
                    pdfUrls : leopardSlipLinks ,
                },
                responseType: 'blob',
            });
            console.log({ response })
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'merged.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLeopardSlipLoading(false);
        } catch (error) {
            setLeopardSlipLoading(false);
            console.error('Error merging and downloading PDFs:', error);
            toast.error(error?.response?.data?.message || error?.message || 'Internal server error.')
        }
    };
    const generateLoadSheetAndGetPDf = async () => {
        try {
            const orderIds = [...new Set(products.map(item => item._id.toString()))];
            console.log(orderIds);

            setLoadSheetLoading(true);
            const response = await Axios.post('/suppliers/get/loadsheet', {
              orderIds : orderIds ,
            });
            console.log({ response })
            const downloadUrl = `${stroageBaseURL + "/products/" +[response.data["data"]["loadSheet"]["file"]]}`;
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'merged.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoadSheetLoading(false);
            toggleAddSubCatPopup(false);
            setShowPopup(false);
            window.location.reload();
        } catch (error) {
            setLoadSheetLoading(false);
            console.error('Error merging and downloading PDFs:', error);
            toast.error(error?.response?.data?.message || error?.message || 'Internal server error.')
        }
    };
    const removeProduct = (index) => {
        const updatedProducts = [...products];
        updatedProducts.splice(index, 1);
        setProducts(updatedProducts);
      };

    return (
        <div className='sm:p-6 py-4 px-2'>
             <div>
    
    
    </div>
            <div className='flex items-center justify-between gap-8'>
                <div className='flex items-center gap-4'>
                    <div>
                        <img 
                        src={UserReportSvg} 
                        alt="Product Report" 
                        className='sm:w-[60px] w-[50px]'
                        />
                    </div>
                    <div className='flex flex-col gap-1'>
                        <h1 className='sm:text-2xl text-lg font-semibold'>
                            Generated load Sheets Overview
                        </h1>
                        
                    </div>
                </div>
                <div className='bg-primary text-white rounded-full px-2 py-1 cursor-pointer text-xl mr-2'>
                    <Link to='/'>
                        <i className="uil uil-estate"></i>
                    </Link>
                </div>
            </div>
            <div className='mt-6 border rounded-md shadow-sm sm:px-4 px-2 pt-2 pb-4'>
                {/* <p className='text-grayText'>Show Data by Date Range</p> */}
                <div className='mt-6'>
                    <form 
                    className='flex md:items-center md:flex-row flex-col gap-6'
                    onSubmit={handleSubmit}
                    >
                        <div className='flex-1 flex flex-col gap-1.5'>
                            <label>Supplier</label>
                            {
                                loading
                                ?
                                    <ClipLoader size={20} />
                                : 
                                suppliers?.length > 0 
                                ? 
                                    <SelectBox 
                                    options={suppliers?.map(item => (
                                        { 
                                            label : item?.firstName + " " + item?.lastName , 
                                            value : item?._id 
                                        }
                                    ))}
                                    value={supplier}
                                    setValue={setSupplier}
                                    required
                                    />
                                : 
                                    <div>``
                                        No Supplier found.
                                    </div>
                            }
                        </div>
                       
                        <div className='flex-[0.5]  mt-7'>
                            <button 
                            className="bg-primary py-3 rounded-md text-pure cursor-pointer hover:bg-primaryHover w-full font-semibold disabled:cursor-not-allowed"
                            type='submit'
                            disabled={!supplier || supplierReportLoading }
                            >
                                {
                                    supplierReportLoading
                                    ? 
                                        <ClipLoader size={20} color='white' />
                                    : 
                                        'SHOW'
                                }
                            </button>
                        </div>
                    </form>
                </div>
                {/* {
                    supplierReport && Object.keys(supplierReport)?.length > 0 && 
                    <div className='flex items-center gap-1 font-semibold mt-2'>
                        <p>Total Delivered Qty :</p>
                        <span>{supplierReport?.totalDeliveredOrders}</span>
                    </div>
                } */}
            </div>
            {
                supplierReportLoading 
                ? 
                    <Loader />
                : 
                supplierReport?.length > 0 
                ? 
                    <div className='shadow-bg mt-6'>
                        <div className='flex items-center justify-between gap-2 sm:py-6 sm:px-5 py-4 px-2'>
                            <div className='text-lg font-semibold'>Load Sheeds  </div>
                            <div className='flex items-center gap-4'>
                                {/* <PDFMergeButton pdfLinks={leopardSlipLinks} /> */}
                                {/* <button className='bg-primary py-1.5 px-6 text-white rounded-md hover:bg-primaryHover disabled:cursor-not-allowed'
                                onClick={() =>{
                                    setShowPopup(true)
                                    setProducts(supplierReport?.orders)
                                    }}
                             >Generate Load Sheet</button>
                                <button className='bg-primary py-1.5 px-6 text-white rounded-md hover:bg-primaryHover disabled:cursor-not-allowed' 
                                onClick={mergeAndDownloadPDFs}
                                disabled={leopardSlipLoading}
                                >
                                    {
                                        leopardSlipLoading 
                                        ? 
                                            <ClipLoader size={20} color='white' />
                                        : 
                                            'Leopard Slip'
                                    }
                                </button>
                                <div className='cursor-pointer' title='Export to pdf'>
                                    <img 
                                    src={Pdf} 
                                    alt="Pdf" 
                                    className='w-[40px]' 
                                    onClick={handlePrint}
                                    />
                                </div> */}
                            </div>
                        </div>
                        { supplierReport&&supplierReport.map((items, index) => (
               
               <div>
                 
                 <div className='flex items-center justify-between pb-3 border-b px-4'>
             
               <div className='flex flex-col items-start gap-2 '>
                 <h3 className='font-semibold'>Shipper Name : {items?.owner?.firstName} {items?.owner?.lastName}</h3>
                 <h3 className='font-semibold'>Phone Number: {items?.owner?.phone}</h3>
               </div>
               <div className='flex flex-col items-start gap-2 '>
                 <h3 className='font-normal'> Load Sheet No: {items?.load_sheet_id}</h3>
                 <h3 className='font-normal'> Created Time: {items?.createdAt}</h3>
               </div>
               <Link
                to={stroageBaseURL + "/products/" +[items?.file]}
                download="Example-PDF-document"
                target="_blank"
                rel="noreferrer"
                className='btn-primary disabled:cursor-not-allowed'
            >
                Download Load Sheet
            </Link>
               {/* <div className='text-xl cursor-pointer' onClick={() => {
                   toggleAddSubCatPopup(false);
                   setShowPopup(false);
               }} >
                   <i className="uil uil-times"></i>
               </div> */}
           </div>
                 
               </div>
             ))}
                        <SupplierReportTable 
                        report={supplierReport}
                        ref={componentRef}
                        />
                    </div>
                : 
                    <ItemNotFound />

            }
           
        </div>
    )
}

export default LoadSheetReport