import SelectBox from "components/global/SelectBox";
import Img1 from 'assets/images/img1.jpg';
import { useEffect, useRef, useState } from "react";
import Heading from "components/global/Heading";
import BackBtn from "components/global/BackBtn";
import { toast } from "react-toastify";
import Axios, { stroageBaseURL } from "config/api";
import { useDispatch, useSelector } from "react-redux";
import { createBanner, _updateBanner } from "redux/actions/bannerActions";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import isBase64 from "utils/isBase64";



const AddNewBanner = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const edit = searchParams.get('edit');
    let editItem;
    if(edit){
        editItem = JSON.parse(localStorage.getItem('editBanner'));
    }else {
        localStorage.removeItem('editBanner')
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bannerImageRef = useRef(null);
    const [name , setName] = useState(editItem?.name || '');
    const [itemType , setItemType] = useState(editItem?.itemType || '');
    const [image , setImage] = useState( 
       edit ? `${stroageBaseURL}/banners/${editItem?.image}` : ''
    );
    const [item , setItem] = useState(editItem?.item?._id || '');
    const [items , setItems] = useState([]);
    const { user } = useSelector(state => state.auth);
    const { updateLoading , createLoading } = useSelector(state => state.banner);

    
    
    
    useEffect(() => {
        if(!itemType || itemType === '') return;
        const fetchItems = async () => {
            try {
                const { data : { data : { docs } } } = await Axios(itemType === 'Product' ? '/products/for-banner' :itemType === 'Category' ? '/categories':'/suppliers/get/all' , {
                    headers : { Authorization : `Bearer ${user.token}`}
                });
                setItems(docs);
            } catch (error) {
                console.log(error)
            }
        }
        fetchItems();
        
    }, [itemType]);


    const handleFileChange = (e) => {
        const file  = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImage(reader.result);
        }
    }

    const submitHandler = async e => {
        e.preventDefault();
       if(edit){
            const data = { item , itemType , name }
            if(isBase64(image)){
                data.image = image;
            }
            console.log(data);
            dispatch(_updateBanner(editItem?._id , data , toast , navigate));
            localStorage.removeItem('editBanner');
       }else {
            if(!item || !image || !name || !itemType) toast.error('All fields are required.');
            const data = { item , itemType , name , image };
            dispatch(createBanner(data , toast , navigate));
       }
    }
    
    return (
        <div className="sm:p-6 py-4 px-2">
            <div className="flex items-center justify-between">
                <Heading title='Add New Banner' />
                <BackBtn />
            </div>
            <div className="shadow-bg sm:p-6 py-4 px-2 mt-6">
                <form 
                className="flex flex-col gap-6"
                onSubmit={submitHandler}
                >
                    <div 
                    className='flex sm:items-center sm:flex-row flex-col gap-6'
                    >
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Title</label>
                            <input 
                            type="text" 
                            placeholder='New Banner'
                            className='textField'
                            value={name}
                            onChange={e => setName(e.target.value)}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Item Type *</label>
                            <SelectBox 
                            options={[
                                {value : "Product" , label : "Product"},
                                {value : "Category" , label : "Category"},
                                {value : "Supplier" , label : "Supplier"},
                            ]} 
                            setValue={setItemType}
                            value={itemType}
                            />
                        </div>
                    </div>
                    <div className='flex sm:items-center sm:flex-row flex-col gap-4 text-[15px]'>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px]'>Banner Image *</label>
                            <input 
                            type='file' 
                            hidden 
                            ref={bannerImageRef}
                            onChange={handleFileChange}
                            />
                            <div className='flex items-center justify-between border px-3 rounded-md cursor-pointer'
                            onClick={() => (
                                bannerImageRef.current.click()
                            )}
                            > 
                                <div>Choose File</div>
                                <div className='py-3 px-6 border-l'>Browse</div>
                            </div>
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            {
                                itemType && 
                                <>
                                    <label className='text-[15px]'>{itemType} *</label>
                                    <SelectBox 
                                    options={items?.map(item => (
                                        { 
                                            value : item?._id , 
                                            label :itemType === 'Product'? `(${item?.owner?.firstName} ${item?.owner?.lastName})`+item?.name:itemType === 'Supplier'?`${item?.firstName} ${item?.lastName}`:item?.name 
                                        }
                                    ))} 
                                    setValue={setItem}
                                    value={item}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className='lg:w-1/2 w-[300px] flex items-center justify-center '>
                        <img 
                        src={image || Img1} 
                        alt="Banner" 
                        className='rounded-lg sm:w-full w-[250px] h-auto' 
                        />
                    </div>
                    <div className="mt-4">
                        <button 
                        type="submit" 
                        className="btn-primary disabled:cursor-not-allowed"
                        disabled={updateLoading || createLoading}
                        >
                            {
                                createLoading || updateLoading 
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Submit'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddNewBanner;