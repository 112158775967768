import Heading from 'components/global/Heading'
import React, {  useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Img1 from 'assets/images/img1.jpg';
import { createNotification } from 'redux/actions/notificationActions';
import { ClipLoader } from 'react-spinners';
import useClickOutside from 'utils/clickOutside';
import { createCategory, updateCat } from 'redux/actions/categoryActions';
import { stroageBaseURL } from 'config/api';
import isBase64 from 'utils/isBase64';
import { useNavigate } from "react-router-dom";

const SendNotification = () => {
    const navigate = useNavigate();

    const [title , setTitle] = useState('');
    const [description , setDescription] = useState('');
    const dispatch = useDispatch();
    const { createLoading , updateLoading } = useSelector(state => state.notification);

    const submitHandler = async (e) => {
        e.preventDefault();
      
            const data = {  title , description }
            await dispatch(createNotification(data , toast ,navigate));
        
            setTitle('');
    }



    return (
        <div className='sm:p-6 px-2 py-4'>
            <div className='flex items-center gap-2'>
                <i className="uil uil-bell"></i>
                <Heading title='Notification' icon={false} />
            </div>
            
            <form 
                    className='flex flex-col gap-4 mt-4'
                    onSubmit={submitHandler}
                    >
                <div className='flex flex-col gap-2'>
                    <label className='text-[15px]'>Title</label>
                    <input 
                    type="text" 
                    placeholder='New Notification'
                    className='textField'
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label className='text-[15px]'>Description</label>
                    <textarea 
                    type="text" 
                    placeholder='Description...'
                    className='textField resize-none focus:border-blue-500 '
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    />
                </div>
                {/* <div className='flex flex-col gap-4 text-[15px]'> */}
                {/* <div className='flex-1 flex flex-col gap-4'>
                                <div className='flex-1 flex flex-col gap-2'>
                                    <label className='text-[15px]'>Image *</label>
                                    <input 
                                    type='file'
                                    ref={imgRef}
                                    onChange={e => handleFileChange(e , setImage)}
                                    hidden 
                                    />
                                    <div className='flex items-center justify-between border sm:px-3 px-2 rounded-md cursor-pointer'
                                    onClick={() => (
                                        imgRef.current.click()
                                    )}
                                    > 
                                        <div>Choose File</div>
                                        <div className='py-3 sm:px-6 px-4 border-l'>Browse</div>
                                    </div>
                                </div>
                                <div className='flex-1 '>
                                    <img 
                                    src={image ? image : Img1} 
                                    alt="Category file" 
                                    className='rounded-lg w-full h-[200px] object-cover'
                                    />
                                </div>
                            </div> */}
                    
                {/* </div> */}
                <div className='mt-4' type='submit'>
                            <button className="btn-primary">
                                {
                                    createLoading || updateLoading
                                    ? 
                                        <ClipLoader size={20} color='#fff' />
                                    :
                                        'Submit'
                                }
                            </button>
                        </div>
               
            </form>
        </div>
    )
}

export default SendNotification