import Axios, { stroageBaseURL } from 'config/api';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import isBase64 from 'utils/isBase64';

const EcommerceSetup = () => {
    const imgRef = useRef(null);
    const [setupData , setSetupData] = useState({
        shopName : '' ,
        currency : '' ,
        email : '' ,
        address : '' ,
        phone : '' ,
        emailVerification : '' ,
        phoneVerification : '' ,
        footerText : '' ,
        logo : '',
        deliveryFee : ''
    });
    const [logo , setLogo] = useState('');
    const { user } = useSelector(state => state.auth);
    
    const [loading , setLoading] = useState(false);

    useEffect(() => {
        const fetchSetupData = async () => {
            try {
                setLoading(true);
                const { data : { data : { doc } } } = await Axios('/setup');
                setSetupData(doc);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
            }   
        }
        fetchSetupData();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setLogo(reader.result);
        };
    };

    const handleChange = e => {
        const { value , name } = e.target;
        setSetupData(prev => ({...prev , [name] : value}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if(!isBase64(setupData.logo)){
            delete setupData.logo;
        }
        try {
            const { data : { data : { doc } } } = await Axios.post('/setup' , setupData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            setSetupData(doc);
            setLoading(false);
            toast.success('Saved successfully.');
        } catch (err) {
            setLoading(false);
            toast.error(err?.response?.data?.message || err?.message || 'Something went wrong.')
        }
    }

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex gap-2 font-semibold text-xl'>
                <i className="uil uil-setting"></i>
                <h3>General Settings Form</h3>
            </div>
            <div className='shadow-bg rounded-md py-4 px-4 mt-4'>
                <form className='flex flex-col gap-4' onSubmit={handleSubmit}>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-6 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>Shop Name</label>
                            <input 
                            type="text" 
                            placeholder='Enter Your ShopName'
                            className='textField'
                            name='shopName'
                            value={setupData?.shopName}
                            onChange={handleChange}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>Currency</label>
                            <select 
                            className='textField' 
                            value={setupData?.currency}
                            onChange={handleChange}
                            name='currency'
                            >
                                <option value="PKR">PKR</option>
                                <option value="USD">USD</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-6 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>Phone</label>
                            <input 
                            type="text" 
                            placeholder='Phone Number'
                            className='textField'
                            name='phone'
                            value={setupData?.phone}
                            onChange={handleChange}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>Email</label>
                            <input 
                            type="text" 
                            placeholder='Your Email'
                            className='textField'
                            name='email'
                            value={setupData?.email}
                            onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-6 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>Address</label>
                            <input 
                            type="text" 
                            placeholder='Shop Address'
                            className='textField'
                            name='address'
                            value={setupData?.address}
                            onChange={handleChange}
                            />
                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>Footer Text</label>
                            <input 
                            type="text" 
                            placeholder='Footer Text'
                            className='textField'
                            name='footerText'
                            value={setupData?.footerText}
                            onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex sm:items-center sm:flex-row flex-col sm:gap-6 gap-4">
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>
                                Email Verification
                            </label>
                            <div className='flex items-center border rounded-md py-1.5 justify-between'>
                                <div className='border-l flex items-center gap-2 flex-1 justify-center'>
                                    <input 
                                    type="radio"
                                    id='emailOn'
                                    checked={setupData?.emailVerification}
                                    name='emailVerification'
                                    onChange={e => {
                                        if(e.target.checked){
                                            setSetupData(prev => (
                                                {
                                                    ...prev , 
                                                    emailVerification : true
                                                }
                                            ))
                                        }
                                    }}
                                    />
                                    <label htmlFor='emailOn'>On</label>
                                </div>
                                <div className='border-l flex items-center gap-2 flex-1 justify-center'>
                                    <input 
                                    type="radio"
                                    id='emailOf'
                                    checked={!setupData?.emailVerification}
                                    name='emailVerification'
                                    onChange={e => {
                                        if(e.target.checked){
                                            setSetupData(prev => (
                                                {
                                                    ...prev , 
                                                    emailVerification : false
                                                }
                                            ))
                                        }
                                    }}
                                    />
                                    <label htmlFor='emailOf'>Off</label>
                                </div>
                            </div>

                        </div>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold text-dark'>
                                Phone Verification
                            </label>
                            <div className='flex items-center border rounded-md py-1.5 justify-between'>
                                <div className='border-l flex items-center gap-2 flex-1 justify-center'>
                                    <input 
                                    type="radio"
                                    id='phoneOn'
                                    name='phoneVerification'
                                    checked={setupData?.phoneVerification}
                                    onChange={e => {
                                        if(e.target.checked){
                                            setSetupData(prev => (
                                                {
                                                    ...prev , 
                                                    phoneVerification : true
                                                }
                                            ))
                                        }
                                    }}
                                    />
                                    <label htmlFor='phoneOn'>On</label>
                                </div>
                                <div className='border-l flex items-center gap-2 flex-1 justify-center'>
                                    <input 
                                    type="radio"
                                    id='phoneOf'
                                    name='phoneVerification'
                                    checked={!setupData?.phoneVerification}
                                    onChange={e => {
                                        if(e.target.checked){
                                            setSetupData(prev => (
                                                {
                                                    ...prev , 
                                                    phoneVerification : false
                                                }
                                            ))
                                        }
                                    }}
                                    />
                                    <label htmlFor='phoneOf'>Off</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 flex flex-col gap-2'>
                        <label className='text-[15px] font-semibold text-dark'>
                            Delivery Charges
                        </label>
                        <input 
                        type="text" 
                        placeholder='Delivery Fee'
                        className='textField'
                        name='deliveryFee'
                        value={setupData?.deliveryFee}
                        onChange={handleChange}
                        />
                    </div>
                    <div className='flex-1 flex flex-col gap-4'>
                        <div className='flex-1 flex flex-col gap-2'>
                            <label className='text-[15px] font-semibold'>
                                Logo
                            </label>
                            <input 
                            type='file'
                            ref={imgRef}
                            onChange={handleFileChange}
                            hidden 
                            />
                            <div className='flex items-center justify-between border sm:px-3 px-2 rounded-md cursor-pointer'
                            onClick={() => (
                                imgRef.current.click()
                            )}
                            > 
                                <div>Choose File</div>
                                <div className='py-3 sm:px-6 px-4 border-l'>Browse</div>
                            </div>
                        </div>
                        <div className='flex-1'>
                            <img 
                            src={logo || `${stroageBaseURL}/logos/${setupData?.logo}`} 
                            alt="Logo" 
                            className='rounded-lg w-full h-[200px] object-cover'
                            />
                        </div>
                    </div>
                    <div>
                        <button 
                        className="btn-primary disabled:cursor-not-allowed"
                        disabled={loading}
                        type='submit'
                        >
                            {
                                loading
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Save'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EcommerceSetup