import CustomersListTable from 'components/customersList/CustomerListTable';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getCustomers } from 'redux/actions/customerActions';

const CustomersList = () => {
    const dispatch = useDispatch();
    const { loading , customers , currentPage } = useSelector(state => state.customer);

    useEffect(() => {
        dispatch(getCustomers(toast));
    } , [currentPage])

    return (
        <div className='sm:p-6 py-4 px-2'>
            <div className='flex items-center gap-2'>
                <Heading title='Customers' icon={false} />
            </div>
            {
                loading 
                ?   
                    <Loader />
                : 
                customers?.length > 0 
                ? 
                    <div className='mt-6'>
                        <CustomersListTable />
                    </div>
                : 
                    <ItemNotFound />
            }
        </div>
    )
}

export default CustomersList;